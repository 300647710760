import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Helyes import
import { UserContext } from '../context/UserContext';
import './Login.css';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    setUserData(null);
    navigate('/login');
  }, [setUserData, navigate]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedToken = localStorage.getItem('token');
      if (storedToken) {
        try {
          const decoded = jwtDecode(storedToken);
          const tokenExpiration = decoded.exp * 1000;
          const remainingTime = tokenExpiration - Date.now();

          if (remainingTime <= 0) {
            handleLogout();
          } else {
            const timer = setTimeout(handleLogout, remainingTime);
            return () => clearTimeout(timer);
          }
        } catch (error) {
          console.error('Hibás token:', error);
          handleLogout();
        }
      }
    };

    checkTokenExpiration();

    const interval = setInterval(checkTokenExpiration, 10000);
    return () => clearInterval(interval);
  }, [handleLogout]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/login`, {
        username,
        password,
      });

      const { accessToken, refreshToken } = response.data;

      const decoded = jwtDecode(accessToken);
      setUserData({
        name: decoded.name,
        neptun: decoded.neptun,
        token: accessToken,
      });

      localStorage.setItem('token', accessToken);
      localStorage.setItem('refreshToken', refreshToken);

      navigate('/');
    } catch (err) {
      console.error('Bejelentkezés sikertelen:', err);
      setError('Helytelen felhasználónév vagy jelszó.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Bejelentkezés</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Felhasználónév:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Jelszó:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Bejelentkezés</button>
        </form>
      </div>
    </div>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { createAlapadat, updateAlapadat, checkAlapadatokBySzakKod, getSzakByKodFromSzakfelelosok } from "../apis/SzakokFinder";
import axios from "axios";
import "./Alapadatok.css";

const Alapadatok = () => {
  const { szakKod } = useParams();
  const [munkarend, setMunkarend] = useState([]);
  const [kepzesiForma, setKepzesiForma] = useState([]);
  const [specializaciok, setSpecializaciok] = useState([]);
  const [isSpecializacio, setIsSpecializacio] = useState(false);
  const [aktivDatum, setAktivDatum] = useState("");
  const [engedelySzam, setEngedelySzam] = useState("");
  const [szakterulet, setSzakterulet] = useState("");
  const [ekkSzint, setEkkSzint] = useState("");
  const [kreditszam, setKreditszam] = useState("");
  const [gondozoEgys, setGondozoEgys] = useState("");
  const [alapadatok, setAlapadatok] = useState([]);
  const [editId, setEditId] = useState(null);
  const [recordExists, setRecordExists] = useState(false);
  const [szakNev, setSzakNev] = useState("");

  const [munkarendOptions, setMunkarendOptions] = useState([]);
  const [kepzesiFormaOptions, setKepzesiFormaOptions] = useState([]);
  const [specializacioOptions, setSpecializacioOptions] = useState([]);
  const [engedelySzamOptions, setEngedelySzamOptions] = useState([]);
  const [szakteruletOptions, setSzakteruletOptions] = useState([]);
  const [ekkSzintOptions, setEkkSzintOptions] = useState([]);
  const [kreditszamOptions, setKreditszamOptions] = useState([]);
  const [gondozoEgysOptions, setGondozoEgysOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const munkarendRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/munkarend_options/${szakKod}`);
        setMunkarendOptions(munkarendRes.data.map(option => ({ value: option.value, label: option.value })));

        const kepzesiFormaRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/kepzesi_forma_options/${szakKod}`);
        setKepzesiFormaOptions(kepzesiFormaRes.data.map(option => ({ value: option.value, label: option.value })));

        const specializacioRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/specializacio_options/${szakKod}`);
        setSpecializacioOptions(specializacioRes.data.map(option => ({ value: option.value, label: option.value })));

        const engedelySzamRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/engedelyszam_options/${szakKod}`);
        setEngedelySzamOptions(engedelySzamRes.data);

        const szakteruletRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/szakterulet_options/${szakKod}`);
        setSzakteruletOptions(szakteruletRes.data);

        const ekkSzintRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/ekkr_szint_options/${szakKod}`);
        setEkkSzintOptions(ekkSzintRes.data);

        const kreditszamRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/kreditszam_options/${szakKod}`);
        setKreditszamOptions(kreditszamRes.data);

        const gondozoEgysRes = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/gondozoi_egysegek_options/${szakKod}`);
        setGondozoEgysOptions(gondozoEgysRes.data);
      } catch (error) {
        console.error("Failed to fetch options:", error);
      }
    };

    const checkExistingRecord = async () => {
      try {
        const response = await checkAlapadatokBySzakKod(szakKod);
        if (response.status === 200 && response.data.status === "success") {
          const data = response.data.data;
          console.log("Existing record data:", data);
          setMunkarend(data.munkarend || []);
          setKepzesiForma(data.kepzesi_forma || []);
          setSpecializaciok(data.specializaciok || []);
          setIsSpecializacio(data.specializaciok && data.specializaciok.length > 0);
          setAktivDatum(data.aktiv_datum ? data.aktiv_datum.split('T')[0] : ""); // Ensure correct date format
          setEngedelySzam(data.engedely_szam || "");
          setSzakterulet(data.szakterulet || "");
          setEkkSzint(data.ekk_szint || "");
          setKreditszam(data.kreditszam || "");
          setGondozoEgys(data.gondozo_egysegek || "");
          setEditId(data.id);
          setRecordExists(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          setRecordExists(false);
        } else {
          console.error("Failed to check for existing record:", error);
        }
      }
    };

    const fetchSzakNev = async () => {
      try {
        const response = await getSzakByKodFromSzakfelelosok(szakKod);
        if (response.status === 200 && response.data.status === "success") {
          setSzakNev(response.data.data.szak_neve);
        }
      } catch (error) {
        console.error("Failed to fetch szak név:", error);
      }
    };

    fetchOptions();
    checkExistingRecord();
    fetchSzakNev();
  }, [szakKod]);

  const handleMunkarendChange = (selectedOptions) => {
    console.log("Selected Munkarend:", selectedOptions);
    setMunkarend(selectedOptions.map(option => option.value));
  };

  const handleKepzesiFormaChange = (selectedOptions) => {
    console.log("Selected KepzesiForma:", selectedOptions);
    setKepzesiForma(selectedOptions.map(option => option.value));
  };

  const handleSpecializacioChange = (selectedOptions) => {
    console.log("Selected Specializaciok:", selectedOptions);
    setSpecializaciok(selectedOptions.map(option => ({ name: option.value })));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      munkarend,
      kepzesiForma,
      specializaciok,
      aktivDatum,
      engedelySzam,
      szakterulet,
      ekkSzint,
      kreditszam,
      gondozoEgys,
      szak_kod: szakKod,
    };
    console.log("Submitting data:", data);
    try {
      if (recordExists) {
        await updateAlapadat(editId, data);
        console.log("Updated successfully");
      } else {
        await createAlapadat(data);
        console.log("Created successfully");
      }
      setRecordExists(true);
      alert("Sikeres mentés!");
    } catch (error) {
      console.error("Failed to save alapadat:", error);
      alert("Mentés sikertelen!");
    }
  };

  return (
    <div className="alapadatok-container">
      <h1>{szakNev}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Munkarend:</label>
          <Select
            isMulti
            options={munkarendOptions}
            value={munkarendOptions.filter(option => munkarend.includes(option.value))}
            onChange={handleMunkarendChange}
          />
        </div>
        <div className="form-group">
          <label>Képzési forma:</label>
          <Select
            isMulti
            options={kepzesiFormaOptions}
            value={kepzesiFormaOptions.filter(option => kepzesiForma.includes(option.value))}
            onChange={handleKepzesiFormaChange}
          />
        </div>
        <div className="form-group">
          <label>Specializációk:</label>
          <Select
            isMulti
            options={specializacioOptions.map(option => ({ value: option.value, label: option.value }))}
            value={specializacioOptions.filter(option => specializaciok.some(spec => spec.name === option.value))}
            onChange={handleSpecializacioChange}
          />
        </div>
        <div className="form-group">
          <label>Mikortól működik aktív szak:</label>
          <input
            type="date"
            value={aktivDatum}
            onChange={(e) => setAktivDatum(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Engedélyszám:</label>
          <select value={engedelySzam} onChange={(e) => setEngedelySzam(e.target.value)}>
            <option value="">Válasszon engedélyszámot</option>
            {engedelySzamOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Szakterület megnevezése:</label>
          <select value={szakterulet} onChange={(e) => setSzakterulet(e.target.value)}>
            <option value="">Válasszon szakterületet</option>
            {szakteruletOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>EKKR-szint:</label>
          <select value={ekkSzint} onChange={(e) => setEkkSzint(e.target.value)}>
            <option value="">Válasszon EKKR-szintet</option>
            {ekkSzintOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Hány kredites a képzés:</label>
          <select value={kreditszam} onChange={(e) => setKreditszam(e.target.value)}>
            <option value="">Válasszon kreditszámot</option>
            {kreditszamOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Képzést gondozó szervezeti egység:</label>
          <select value={gondozoEgys} onChange={(e) => setGondozoEgys(e.target.value)}>
            <option value="">Válasszon szervezeti egységet</option>
            {gondozoEgysOptions.map((option, index) => (
              <option key={index} value={option.value}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Mentés</button>
      </form>
    </div>
  );
};

export default Alapadatok;

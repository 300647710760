import React, { useEffect, useState, useContext } from "react";
import Tile from "../components/Tile";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { SzakokContext } from "../context/SzakokContext";
import { UserContext } from "../context/UserContext"; 
import SzakokFinder from "../apis/SzakokFinder";
import { v4 as uuidv4 } from "uuid";
import "./SzakjelentesLetrehozas.css"; 

const getLocalDateTime = () => {
    const date = new Date();
    return date.toLocaleString("sv-SE", {
        hour12: false,
        timeZone: "Europe/Budapest"
    }).replace(" ", "T").slice(0, 16);
};



// Félév generálása az aktuális dátum alapján (szeptember 1-jével mint fordulópont)
const generateCurrentFelev = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const septemberFirst = new Date(currentYear, 8, 1); // Szeptember 1. (8. hónap, mert a hónapok indexelése 0-tól indul)

    let startYear;
    if (today < septemberFirst) {
        // Ha még szeptember előtt vagyunk, az előző tanévet kell generálni
        startYear = currentYear - 2;
    } else {
        // Ha már szeptember után vagyunk, az aktuális tanévet generáljuk
        startYear = currentYear - 1;
    }

    const endYear = (startYear + 1).toString().slice(-2);
    return `${startYear}/${endYear}`;
};

function SzakjelentesLetrehozas() {
    const { szakfelelosok, setSelectedSzakfelelos, setSzakok } = useContext(SzakokContext);
    const { userData } = useContext(UserContext);  
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!sessionStorage.getItem('refreshed')) {
            sessionStorage.setItem('refreshed', 'true');
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (userData && userData.name) {
            const filtered = szakfelelosok.filter((item) => item.szakfelelos_neve === userData.name);
            setFilteredData(filtered);
        }
    }, [userData, szakfelelosok]);

    const handleTileClick = async (szakfelelos) => {
        const newSzakId = uuidv4();
        const selectedFelev = generateCurrentFelev(); // Félév generálása

        const newSzakData = {
            szak_id: newSzakId,
            name: szakfelelos.kepzes_neve,
            szak_kod: szakfelelos.szak_kod,
            szakfelelos_nev: szakfelelos.szakfelelos_neve,
            modosito_neve: userData.name,
            felev: selectedFelev, // Helyesen beállítva a félév
            status: 1,
            modositas_datuma: getLocalDateTime(),
        };

        try {
            const response = await SzakokFinder.post("/szakok", newSzakData);
            setSzakok((prevSzakok) => [...prevSzakok, response.data]);
            setSelectedSzakfelelos(szakfelelos);
            navigate(`/szakjelentesek/${newSzakId}/alapadatok`);
        } catch (err) {
            console.error("Nem sikerült létrehozni az új szakot:", err);
        }
    };

    return (
        <div className="szakjelentes-letrehozas">
            <div className="header">
                <h1>Szakjelentés létrehozása</h1>
            </div>
            <div className="tiles">
                {filteredData.map((item) => (
                    <Tile
                        key={item.id}
                        icon={<i className="material-icons">add</i>}
                        text={`${item.kepzes_neve} - ${item.kepzesi_szint}`} 
                        onClick={() => handleTileClick(item)}
                    />
                ))}
            </div>
        </div>
    );
}

export default SzakjelentesLetrehozas;

import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

// Axios példány létrehozása
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Az API alap URL-je a környezeti változóból
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true // Hitelesítési adatok engedélyezése
});

// Token és refresh token lekérése
const getToken = () => localStorage.getItem('token');
const getRefreshToken = () => localStorage.getItem('refreshToken');

// Felhasználó kijelentkeztetése és átirányítás a bejelentkezési oldalra
const logoutUser = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

// Axios interceptor a token hozzáadásához
api.interceptors.request.use(
  async (config) => {
    let token = getToken();

    // Ellenőrizzük a token lejárati idejét
    if (token) {
      const decoded = jwtDecode(token);
      const isTokenExpired = decoded.exp * 1000 < Date.now();

      if (isTokenExpired) {
        try {
          const refreshToken = getRefreshToken();
          if (refreshToken) {
            // Friss access token kérése a refresh token alapján
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/token`, { token: refreshToken });
            const newAccessToken = response.data.accessToken;
            
            // Frissített access token mentése
            localStorage.setItem('token', newAccessToken);
            token = newAccessToken; // Friss token hozzáadása a confighoz
          } else {
            logoutUser();
            return Promise.reject(new Error("Refresh token missing, logging out."));
          }
        } catch (error) {
          console.error("Failed to refresh token", error);
          logoutUser();
          return Promise.reject(error);
        }
      }

      // Frissített vagy eredeti token hozzáadása a kéréshez
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      logoutUser();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Axios interceptor a hibakezeléshez (401 hiba esetén kijelentkeztetés)
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    // Ha a hiba 401-es (Unauthorized), az azt jelenti, hogy lejárt a token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      
      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          logoutUser();
          return Promise.reject(error);
        }

        // Refresh tokennel új access token kérése
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/token`, { token: refreshToken });
        const newAccessToken = response.data.accessToken;
        
        // Új access token mentése és kérés újbóli elküldése
        localStorage.setItem('token', newAccessToken);
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        
        return axios(originalRequest);
      } catch (refreshError) {
        logoutUser();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error); 
  }
);

export default api;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Vélemények szekció CSS stílusok */

.opinion-section {
    background-color: #f9f9f9;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.opinion-section h3 {
    color: #444;
    font-size: 1.6rem;
    margin-bottom: 15px;
}

.opinion-section p {
    font-size: 1rem;
    color: #666;
    margin-bottom: 15px;
}

.opinion-dropzone {
    padding: 20px;
    border: 2px dashed #007bff;
    background-color: #eef5ff;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 15px;
}

.opinion-dropzone:hover {
    background-color: #dce9ff;
}

.uploaded-opinions-list {
    list-style-type: none;
    padding: 0;
}

.uploaded-opinions-list li {
    display: flex;
    align-items: center;
    padding: 8px 0;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.uploaded-opinions-list li:hover {
    background-color: #f1f1f1;
}

.pdf-icon {
    margin-right: 8px;
    color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/routes/SzakjelentesLetrehozas.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":["/* Vélemények szekció CSS stílusok */\n\n.opinion-section {\n    background-color: #f9f9f9;\n    padding: 20px;\n    margin-top: 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n}\n\n.opinion-section h3 {\n    color: #444;\n    font-size: 1.6rem;\n    margin-bottom: 15px;\n}\n\n.opinion-section p {\n    font-size: 1rem;\n    color: #666;\n    margin-bottom: 15px;\n}\n\n.opinion-dropzone {\n    padding: 20px;\n    border: 2px dashed #007bff;\n    background-color: #eef5ff;\n    text-align: center;\n    cursor: pointer;\n    border-radius: 8px;\n    margin-bottom: 15px;\n}\n\n.opinion-dropzone:hover {\n    background-color: #dce9ff;\n}\n\n.uploaded-opinions-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.uploaded-opinions-list li {\n    display: flex;\n    align-items: center;\n    padding: 8px 0;\n    cursor: pointer;\n    transition: background-color 0.2s ease;\n}\n\n.uploaded-opinions-list li:hover {\n    background-color: #f1f1f1;\n}\n\n.pdf-icon {\n    margin-right: 8px;\n    color: #007bff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

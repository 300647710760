import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Axios instance creation
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Axios interceptor for adding JWT token to each request
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;  // Set JWT token for each request
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Szakok endpoints
export const getSzakok = () => api.get("/szakok");
export const getSzak = (id) => api.get(`/szakok/${id}`);
export const createSzak = (data) => api.post("/szakok", {
  szak_id: data.szak_id,
  name: data.name,
  felev: data.felev,
  status: data.status,
  szak_kod: data.szak_kod,
  kepzesi_forma: data.kepzesi_forma,
  modosito_neve: data.modosito_neve,
  modositas_datuma: data.modositas_datuma,
  szakfelelos_id: data.szakfelelos_id
});
export const updateSzak = (id, data) => api.put(`/szakok/${id}`, {
  name: data.name,
  felev: data.felev,
  status: data.status,
  szak_kod: data.szak_kod,
  kepzesi_forma: data.kepzesi_forma,
  modosito_neve: data.modosito_neve,
  szakfelelos_id: data.szakfelelos_id
});
export const deleteSzak = (id) => api.delete(`/szakok/${id}`);

// Képzés célja endpoint (Módosítva)
export const getKepzesCelja = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`);
export const createKepzesCelja = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`, data);
export const updateKepzesCelja = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`, data);
export const deleteKepzesCelja = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`);

// Szak mintaterve endpoint
export const getSzakMintaTerve = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`);
export const createSzakMintaTerve = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`, data);
export const updateSzakMintaTerve = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`, data);
export const deleteSzakMintaTerve = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`);

// Szak létszám adatok endpoint
export const getSzakLetszamAdatok = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`);
export const createSzakLetszamAdatok = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`, data);
export const updateSzakLetszamAdatok = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`, data);
export const deleteSzakLetszamAdatok = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`);

// Szak kommunikációja endpoint
export const getSzakKomm = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`);
export const createSzakKomm = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`, data);
export const updateSzakKomm = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`, data);
export const deleteSzakKomm = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`);

// Fejlesztési javaslatok endpoint
export const getFejlesztesiJavaslatok = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`);
export const createFejlesztesiJavaslatok = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`, data);
export const updateFejlesztesiJavaslatok = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`, data);
export const deleteFejlesztesiJavaslatok = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`);

// Tehetséges hallgatók endpoint
export const getTehetsegesHallgatok = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`);
export const createTehetsegesHallgatok = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`, data);
export const updateTehetsegesHallgatok = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`, data);
export const deleteTehetsegesHallgatok = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`);

// Versenytárs elemzés endpoint
export const getVersenytarsElemzes = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`);
export const createVersenytarsElemzes = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`, data);
export const updateVersenytarsElemzes = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`, data);
export const deleteVersenytarsElemzes = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`);

// Tanulmányi körülmények endpoint
export const getTanulmanyiKorulmenyek = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`);
export const createTanulmanyiKorulmenyek = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`, data);
export const updateTanulmanyiKorulmenyek = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`, data);
export const deleteTanulmanyiKorulmenyek = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`);

// Idegen nyelvi körülmények endpoint
export const getIdegenNyelviKorulmenyek = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`);
export const createIdegenNyelviKorulmenyek = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`, data);
export const updateIdegenNyelviKorulmenyek = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`, data);
export const deleteIdegenNyelviKorulmenyek = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/idegen-nyelvi-korulmenyek`);

// Kötelező szakmai gyakorlat endpoint
export const getKotelezoSzakmaiGyakorlat = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`);
export const createKotelezoSzakmaiGyakorlat = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`, data);
export const updateKotelezoSzakmaiGyakorlat = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`, data);
export const deleteKotelezoSzakmaiGyakorlat = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`);

// Mintatanterv fejlesztések endpoint
export const getMintatantervFejlesztesek = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`);
export const createMintatantervFejlesztesek = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`, data);
export const updateMintatantervFejlesztesek = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/mintatanterv-fejlesztesek`, data);

// Oktatás endpointok
export const getOktatas = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/oktatas`);
export const createOktatas = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/oktatas`, data);
export const updateOktatas = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/oktatas`, data);
export const deleteOktatas = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/oktatas`);

// Meghívott előadók és óraadók endpointok
export const getMeghivottEloadok = (szakId) => api.get(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`);
export const createMeghivottEloadok = (szakId, data) => api.post(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`, data);
export const updateMeghivottEloadok = (szakId, data) => api.put(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`, data);
export const deleteMeghivottEloadok = (szakId) => api.delete(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`);

// Szakfelelosok endpointok

// Összes szakfelelős adatainak lekérése
export const getSzakfelelosok = () => api.get("/szakfelelosok");

// Egy szakfelelős adatainak lekérése ID alapján
export const getSzakfelelosById = (id) => api.get(`/szakfelelosok/${id}`);

// Szakfelelős adatainak lekérése szak_kod alapján
export const getSzakfelelosBySzakkod = async (szakkod) => {
  try {
    const response = await api.get(`/szakfelelosok/szakkod/${encodeURIComponent(szakkod)}`);
    return response.data;  // Ensure proper return of data
  } catch (error) {
    console.error("Error fetching data by szakkod:", error);
    throw error;
  }
};

// Új szakfelelős létrehozása
export const createSzakfelelos = (data) => api.post("/szakfelelosok", {
  kepzes_neve: data.kepzes_neve,
  kepzesi_szint: data.kepzesi_szint,
  kepzesi_terulet: data.kepzesi_terulet,
  kreditszam: data.kreditszam,
  munkarend: data.munkarend,
  kepzesi_forma: data.kepzesi_forma,
  specializaciok: data.specializaciok,
  engedelyszam: data.engedelyszam,
  szak_inditas: data.szak_inditas,
  kepzest_gondozo_kar: data.kepzest_gondozo_kar,
  kepzest_gondozo_tanszek: data.kepzest_gondozo_tanszek,
  szakfelelos_neve: data.szakfelelos_neve,
  szakfelelos_neptun_kodja: data.szakfelelos_neptun_kodja
});

// Szakfelelős adatainak frissítése ID alapján
export const updateSzakfelelos = (id, data) => api.put(`/szakfelelosok/${id}`, {
  kepzes_neve: data.kepzes_neve,
  kepzesi_szint: data.kepzesi_szint,
  kepzesi_terulet: data.kepzesi_terulet,
  kreditszam: data.kreditszam,
  munkarend: data.munkarend,
  kepzesi_forma: data.kepzesi_forma,
  specializaciok: data.specializaciok,
  engedelyszam: data.engedelyszam,
  szak_inditas: data.szak_inditas,
  kepzest_gondozo_kar: data.kepzest_gondozo_kar,
  kepzest_gondozo_tanszek: data.kepzest_gondozo_tanszek,
  szakfelelos_neve: data.szakfelelos_neve,
  szakfelelos_neptun_kodja: data.szakfelelos_neptun_kodja
});

// Szakfelelős törlése ID alapján
export const deleteSzakfelelos = (id) => api.delete(`/szakfelelosok/${id}`);

// Közös endpoint
export const createSzakData = (data) => api.post("/createSzakData", data);

// Új API hívás a szakfelelősök adatainak lekérdezéséhez a szakId alapján
export const getSzakFelelosLeirasBySzakId = (szakId) => api.get(`/szakfelelos-leiras/${szakId}`);

// Új API hívás a szakfelelősök adatainak lekérdezéséhez a szakKod alapján
export const getSzakByKodFromSzakfelelosok = (szakKod) => api.get(`/szakfelelosok/kod/${szakKod}`);

// Új API hívás a szakfelelősök szak_kod szerinti lekérdezéséhez
export const getSzakByKod = (szakKod) => api.get(`/szakok/kod/${szakKod}`);

// Új API hívás az alapadatok ellenőrzéséhez a szakKod alapján
export const checkAlapadatokBySzakKod = (szakKod) => api.get(`/alapadatok/check/${szakKod}`);

// Alapadatok endpoint
export const getAlapadatok = () => api.get("/alapadatok");
export const getAlapadatokById = (id) => api.get(`/alapadatok/${id}`);
export const getAlapadatokBySzakKod = (szakKod) => api.get(`/alapadatok/${szakKod}`);
export const createAlapadat = (data) => api.post("/alapadatok", data);
export const updateAlapadat = (identifier, data) => api.put(`/alapadatok/${identifier}`, data);
export const deleteAlapadat = (id) => api.delete(`/alapadatok/${id}`);

// Új API hívás a szakId státuszának lekérdezéséhez
export const getSectionStatus = async (szakId) => {
  try {
    const response = await api.get(`/szakjelentes_letrehozas/${szakId}/status`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching section statuses:", error);
    throw error;
  }
};

// Általános API hívás az opciós táblákhoz
export const fetchOptions = async (tableName) => {
  try {
      const response = await api.get(`/${tableName}`);
      return response.data;
  } catch (err) {
      console.error(`Failed to fetch options from ${tableName}:`, err);
      throw err;
  }
};

export const addOption = async (tableName, value) => {
  try {
      const response = await api.post(`/${tableName}`, { value });
      return response.data;
  } catch (err) {
      console.error(`Failed to add option to ${tableName}:`, err);
      throw err;
  }
};

export const updateOption = async (tableName, id, value) => {
  try {
      const response = await api.put(`/${tableName}/${id}`, { value });
      return response.data;
  } catch (err) {
      console.error(`Failed to update option in ${tableName}:`, err);
      throw err;
  }
};

export const deleteOption = async (tableName, id) => {
  try {
      const response = await api.delete(`/${tableName}/${id}`);
      return response.data;
  } catch (err) {
      console.error(`Failed to delete option from ${tableName}:`, err);
      throw err;
  }
};

// Új API végpontok a szöveges vélemények kezelésére

// Összes vélemény lekérése adott szak_id alapján
export const getOpinions = (szakId) => api.get(`/velemenyek/${szakId}`);

// Új vélemény létrehozása
export const createOpinion = (szakId, content) => 
  api.post(`/velemenyek`, { szak_id: szakId, content });

// Vélemény törlése vélemény azonosító alapján
export const deleteOpinion = (opinionId) => api.delete(`/velemenyek/${opinionId}`)





export default api;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tiles {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  margin: 20px;
  padding: 20px;
  background-color: #ffffff; /* White background */
  border: 1px solid #dcdcdc; /* Light grey border */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: black; /* Black text color */
  cursor: pointer;
  transition: transform 0.2s;
}

.tile:hover {
  transform: scale(1.05);
}

.tile p {
  margin-top: 10px;
  font-size: 18px;
  text-align: center;
  font-weight: normal; /* Regular font weight */
  color: black; /* Black text color */
}

.tile i {
  color: #50ADC9; /* Icon color */
}
`, "",{"version":3,"sources":["webpack://./src/components/Tile.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,SAAS;EACT,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,aAAa;EACb,yBAAyB,EAAE,qBAAqB;EAChD,yBAAyB,EAAE,sBAAsB;EACjD,mBAAmB;EACnB,2CAA2C;EAC3C,YAAY,EAAE,qBAAqB;EACnC,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,mBAAmB,EAAE,wBAAwB;EAC7C,YAAY,EAAE,qBAAqB;AACrC;;AAEA;EACE,cAAc,EAAE,eAAe;AACjC","sourcesContent":[".tiles {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  flex-wrap: wrap;\n  gap: 20px;\n  padding: 20px;\n}\n\n.tile {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 200px;\n  height: 200px;\n  margin: 20px;\n  padding: 20px;\n  background-color: #ffffff; /* White background */\n  border: 1px solid #dcdcdc; /* Light grey border */\n  border-radius: 10px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  color: black; /* Black text color */\n  cursor: pointer;\n  transition: transform 0.2s;\n}\n\n.tile:hover {\n  transform: scale(1.05);\n}\n\n.tile p {\n  margin-top: 10px;\n  font-size: 18px;\n  text-align: center;\n  font-weight: normal; /* Regular font weight */\n  color: black; /* Black text color */\n}\n\n.tile i {\n  color: #50ADC9; /* Icon color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

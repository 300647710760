import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getKepzesCelja, createKepzesCelja, updateKepzesCelja, getSzakFelelosLeirasBySzakId } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import "./KepzesCelja.css";

const KepzesCelja = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [kepzesCelja, setKepzesCelja] = useState({
        kepzes_celja_content: "",
        kompetenciatérkép_content: "",
        bemeneti_kovetelmeny: "",
        kkk_content: "",
        filling_status: 1 // Default value for filling_status
    });

    const [isNew, setIsNew] = useState(true);

    // Adatok lekérdezése és új bejegyzés létrehozása, ha szükséges
    useEffect(() => {
        const fetchData = async () => {
            try {
                const kepzesCeljaResponse = await getKepzesCelja(szakId);
                
                // Ellenőrzés, hogy van-e már adat
                if (kepzesCeljaResponse.data.data.kepzesCelja.length > 0) {
                    setKepzesCelja(kepzesCeljaResponse.data.data.kepzesCelja[0]);
                    setIsNew(false);
                } else {
                    // Ha 404-es hiba érkezik, új rekord létrehozása
                    console.log("No data found, creating a new record with filling_status = 1");
                    const newKepzesCelja = { 
                        kepzes_celja_content: "", 
                        kompetenciatérkép_content: "", 
                        bemeneti_kovetelmeny: "", 
                        kkk_content: "", 
                        filling_status: 1 
                    };
                    await createKepzesCelja(szakId, newKepzesCelja); // Új rekord létrehozása
                    setKepzesCelja(newKepzesCelja); // Helyi állapot frissítése
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    console.log("404 Error: No data found for the given UUID, creating a new entry with filling_status = 1");
                    const newKepzesCelja = { 
                        kepzes_celja_content: "", 
                        kompetenciatérkép_content: "", 
                        bemeneti_kovetelmeny: "", 
                        kkk_content: "", 
                        filling_status: 1 
                    };
                    await createKepzesCelja(szakId, newKepzesCelja); // Új rekord létrehozása
                    setKepzesCelja(newKepzesCelja); // Helyi állapot frissítése
                    window.location.reload(); // Oldal frissítése

                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId, navigate]);

    useEffect(() => {
        const textareas = document.querySelectorAll("textarea");
        textareas.forEach((textarea) => {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    }, [kepzesCelja]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setKepzesCelja((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...kepzesCelja, filling_status: status };
        try {
            if (isNew) {
                await createKepzesCelja(szakId, updatedData);
            } else {
                await updateKepzesCelja(szakId, updatedData);
            }
            window.location.reload(); // Oldal újratöltése a mentés után
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/szak-mintaterve`);
    };

    const handleBack = () => {
        navigate(`/szakjelentesek/${szakId}/alapadatok`);
    };

    const isFormValid = () => {
        return (
            kepzesCelja.kepzes_celja_content &&
            kepzesCelja.kompetenciatérkép_content &&
            kepzesCelja.bemeneti_kovetelmeny &&
            kepzesCelja.kkk_content
        );
    };

    const handleFinalize = async (e) => {
        if (kepzesCelja.filling_status === 3) {
            // Visszavonás
            const updatedData = { ...kepzesCelja, filling_status: 2 }; // Status 2-re állítása
            await updateKepzesCelja(szakId, updatedData); // Frissítés az adatbázisban
            setKepzesCelja(updatedData); // Helyi állapot frissítése
        } else {
            if (isFormValid()) {
                handleSubmit(e, 3); // Véglegesítés
            } else {
                alert("Minden szöveges mezőt ki kell tölteni!");
            }
        }
        window.location.reload(); // Oldal frissítése
    };

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.1 Minőségbiztosítási politika</h1>
                <h2>1. A képzés</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h3>1.1. A képzés célja</h3>
                        <p>Kérjük a képzés céljára vonatkozó információt az érvényes KKK alapján töltse ki: <a href="https://kormany.hu/dokumentumtar/kepzesi-es-kimeneti-kovetelmenyek-1" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="kepzes_celja_content"
                                value={kepzesCelja.kepzes_celja_content}
                                onChange={handleChange}
                                placeholder="Írja be a képzés célját"
                                disabled={kepzesCelja.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>
                    </div>

                    <div className="card">
                        <h3>1.2. Kompetenciatérkép</h3>
                        <p>Kérjük, határozza meg a hard és soft skill-eket, amelyek a szak kimeneti követelményeihez kapcsolódnak a mintatanterv, a KKK (tudás, képesség, készség) illetve az ESCO adatbázis segítségével: <a href="https://esco.ec.europa.eu/hu/classification/skill_main" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="kompetenciatérkép_content"
                                value={kepzesCelja.kompetenciatérkép_content}
                                onChange={handleChange}
                                placeholder="Írja be a kompetenciatérkép tartalmát"
                                disabled={kepzesCelja.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>
                    </div>

                    <div className="card">
                        <h3>1.3. A képzés bemeneti követelményei</h3>
                        <p>Kérjük, a követelményeket röviden írják le: pl. alapképzési szakon és osztatlan képzési szakon: emelt érettségi kell-e, ha igen, milyen tárgyból, mely tantárgyakból történik pontszámítás (szakmai előkészítő tárgyak, ágazati szakmai érettségi vizsgatárgyak, ágazaton belüli specializáció szakmai érettségi vizsgatárgyak külön részletezése nem szükséges, elég a fő halmaz nevének beírása. Forrás: <a href="https://felveteli.sze.hu/kezdolap" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="bemeneti_kovetelmeny"
                                value={kepzesCelja.bemeneti_kovetelmeny}
                                onChange={handleChange}
                                placeholder="Írja be a képzés bemeneti követelményeit"
                                disabled={kepzesCelja.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>
                    </div>

                    <div className="card">
                        <h3>1.4. Képzési és kimeneti követelmények</h3>
                        <p>Kérjük a szakra vonatkozó képzési és kimeneti követelményeket az érvényes KKK alapján töltse ki: <a href="https://kormany.hu/dokumentumtar/kepzesi-es-kimeneti-kovetelmenyek-1" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="kkk_content"
                                value={kepzesCelja.kkk_content}
                                onChange={handleChange}
                                placeholder="Írja be a képzési és kimeneti követelményeket"
                                disabled={kepzesCelja.filling_status === 3} // Disable if filling_status is 3
                            />
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" type="button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" type="button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={kepzesCelja.filling_status === 3}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {kepzesCelja.filling_status === 3 ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default KepzesCelja;

import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getMintatantervFejlesztesek, createMintatantervFejlesztesek, updateMintatantervFejlesztesek } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";


const MintatantervFejlesztesek = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [mintatantervData, setMintatantervData] = useState({
        fejlesztes_leiras: "",
        filling_status: 1
    });

    const [isNew, setIsNew] = useState(true);
    const [images, setImages] = useState([]);
    const [finalized, setFinalized] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getMintatantervFejlesztesek(szakId);
                if (response.data && response.data.data) {
                    setMintatantervData(response.data.data);
                    setFinalized(response.data.data.filling_status === 3);
                    await fetchImages();
                    setIsNew(false);
                } else {
                    // Ha 404-es hiba, új rekord feltöltése
                    const newData = { fejlesztes_leiras: "", filling_status: 1 };
                    await createMintatantervFejlesztesek(szakId, newData);
                    setMintatantervData(newData);
                    window.location.reload(); // Az oldal újratöltése új rekord létrehozása után
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/mintatanterv_fejlesztesek_image`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
    
            const data = await response.json();
            setImages(data.data || []);
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...mintatantervData, [name]: value };
        setMintatantervData(updatedData);
    };

    const handleDrop = async (acceptedFiles) => {
        if (finalized) return; // Véglegesítés után ne lehessen képet feltölteni
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', 'mintatanterv_fejlesztesek_image');
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => [...prevImages, { ...uploadResponse, image_path: imagePath }]);
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    

    const handleDeleteImage = async (imageId) => {
        if (finalized) return; // Véglegesítés után ne lehessen törölni képet

        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });

            setImages(images.filter(img => img.id !== imageId));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/fejlesztesi-javaslatok`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`);
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...mintatantervData, filling_status: status };

        try {
            if (isNew) {
                await createMintatantervFejlesztesek(szakId, updatedData);
            } else {
                await updateMintatantervFejlesztesek(szakId, updatedData);
            }

            window.location.reload(); // Az oldal újratöltése mentés vagy véglegesítés után
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*',
        disabled: finalized // Véglegesítés esetén le van tiltva a képfeltöltés
    });

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                  "Szak alapadatok",
                  "A képzés",
                  "A szak mintatanterve",
                  "Tehetséges, kiemelkedő hallgatói teljesítmények",
                  "Idegen nyelvi követelmények",
                  "Kötelező szakmai gyakorlat",
                  "Mintatanterv fejlesztések, módosítások",
                  "Fejlesztési javaslatok",
                  "Oktatás",
                  "Hallgatókra vonatkozó adatok",
                  "Versenytárs elemzés",
                  "Meghívott előadók és óraadók",
                  "A képzés tanulmányi körülményei",
                  "A szak kommunikációja",
                  "Szakjelentés lezárása"
            ]} />
            <div className="uj-szak-container">
                <h1>6 Mintatanterv fejlesztések, módosítások</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>6 Mintatanterv fejlesztések, módosítások</h2>
                        <p>
                        Szöveges összefoglalás arról, hogy a vizsgált időszakban milyen fejlesztések, módosítások valósultak meg a szak működésében, a szak mintatantervében (szerepeljen a mintatanterv változások indoklása, életbe lépésének ideje és rövid leírása is). Kérjük, mutassa be, hogyan felelnek meg a fejlesztések a PDCA ciklusnak.
                        </p>
                        <div className="form-group">
                            <textarea
                                name="fejlesztes_leiras"
                                value={mintatantervData.fejlesztes_leiras}
                                onChange={handleChange}
                                placeholder="Írja le a fejlesztéseket, módosításokat..."
                                disabled={finalized} // Véglegesítés esetén ne lehessen szöveget szerkeszteni
                            />
                        </div>

                        <div className="form-group">
                            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                                <input {...getInputProps()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép"
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id)} 
                                            className="delete_image_button_small"
                                            disabled={finalized} // Véglegesítés esetén ne lehessen képet törölni
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={finalized}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={(e) => handleSubmit(e, finalized ? 2 : 3)}>
                            {finalized ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MintatantervFejlesztesek;

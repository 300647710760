import React from "react";
import '../components/LargeTile.css'; 

function LargeTile({ text }) {
    return (
        <div className="large-tile">
            {text.split('\n').map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>
    );
}

export default LargeTile;

import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../App.css";
import SzakokFinder from '../apis/SzakokFinder';  // API hívás
import { UserContext } from '../context/UserContext';  // Felhasználói adatok context

function NavBar({ sidebar, setSidebar, responsive }) {
    const { userData, setUserData } = useContext(UserContext);
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [szakok, setSzakok] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSzakok = async () => {
            try {
                console.log("🔍 [FRONTEND] fetchSzakok() függvény meghívva...");
                console.log("📥 [FRONTEND] userData értéke:", userData);

                if (!userData || (!userData.neptun && !userData.isSuperUser)) {
                    console.warn("⚠️ [FRONTEND] Nincs megfelelő userData vagy hiányzó Neptun kód!");
                    return;
                }

                const token = localStorage.getItem("token");
                if (!token) {
                    console.error("❌ [FRONTEND] Nincs token elmentve! Az API hívás sikertelen lesz!");
                    return;
                }

                console.log("🔐 [FRONTEND] Token megtalálva, API hívás indul...");
                console.log("📡 [FRONTEND] Küldött API kérés:", {
                    url: `/szakfelelosok/szakok/${userData.neptun}`,
                    headers: { Authorization: `Bearer ${token}` }
                });

                const response = await SzakokFinder.get(
                    `/szakfelelosok/szakok/${userData.neptun}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                console.log("✅ [FRONTEND] API válasz sikeres:", response);
                console.log("📊 [FRONTEND] Fetched szakok:", response.data.data);

                setSzakok(response.data.data);
                setIsLoading(false);
            } catch (error) {
                console.error("❌ [FRONTEND] Nem sikerült lekérni a szakokat:", error);
                setIsLoading(false);
            }
        };

        fetchSzakok();
    }, [userData]);

    const showSidebar = () => setSidebar(!sidebar);

    const toggleSubmenu = (e) => {
        e.stopPropagation();
        setSubmenuOpen(!submenuOpen);
    };

    const handleSubNavClick = (szakKod) => {
        try {
            navigate(`/szakinformacio/${szakKod}`);
            showSidebar();
        } catch (error) {
            console.error("Nem sikerült navigálni a szak információ oldalra:", error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        setUserData(null);
        navigate("/login");
    };

    return (
        <>
            <div className='navbar'>
                <Link to='#' className='menu-bars'>
                    <i className="material-icons" onClick={showSidebar}>menu</i>
                </Link>
                <div className='user-info'>
                    <div className='user-details'>
                        <span>{userData?.name ? userData.name : "Ismeretlen felhasználó"}</span>
                        <span>{userData?.neptun ? userData.neptun : "Ismeretlen kód"}</span>
                    </div>
                    <button className="logout-button" onClick={handleLogout} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <i className="material-icons">power_settings_new</i>
                    </button>
                </div>
            </div>
            <nav className={`${sidebar ? 'nav-menu active' : 'nav-menu'} ${responsive ? 'responsive' : ''}`}>
                <ul className='nav-menu-items'>
                    <li className='navbar-toggle'>
                        <Link to='#' className='menu-bars'>
                            <i className="material-icons" onClick={showSidebar}>close</i>
                        </Link>
                    </li>
                    <li className='nav-text'>
                        <Link to='/'>
                            <i className="material-icons">home</i>
                            <span>Fő oldal</span>
                        </Link>
                    </li>

                    {!userData?.isSuperUser && (
                        <li className='nav-text'>
                            <Link to='#' onClick={toggleSubmenu}>
                                <i className="material-icons">info</i>
                                <span>Szak alapadatok</span>
                            </Link>
                        </li>
                    )}

                    {submenuOpen && !userData?.isSuperUser && (
                        isLoading ? (
                            <li className="nav-sub-text">Betöltés...</li>
                        ) : (
                            szakok.length > 0 ? (
                                szakok.map((szak, index) => (
                                    <li key={index} className="nav-sub-text">
                                        <a href="#" onClick={() => handleSubNavClick(szak.szak_kod)}>
                                            <i className="material-icons">arrow_right</i>
                                            <span>{`${szak.kepzes_neve} - ${szak.kepzesi_szint}`}</span>
                                        </a>
                                    </li>
                                ))
                            ) : (
                                <li className="nav-sub-text">Nincs elérhető szak</li>
                            )
                        )
                    )}

                    <li className='nav-text'>
                        <Link to='/szakjelentesek'>
                            <i className="material-icons">description</i>
                            <span>Szakjelentések</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default NavBar;

import React, { useContext, useEffect } from 'react';
import "../App.css"; 
import { Outlet } from 'react-router-dom';
import { SzakokContext } from '../context/SzakokContext';
import Szakfelelosok from "../data/Szakfelelosok.json"; // Import the JSON file

function Szakinfo() {
    const { setSzakok } = useContext(SzakokContext);

    useEffect(() => {
        // Load data from JSON file
        setSzakok(Szakfelelosok);
    }, [setSzakok]);

    return (
        <div className="szakinfo"> 
            <h1>Szakinfo</h1>
            <Outlet/>
        </div>
    );
}

export default Szakinfo;

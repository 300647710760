import React, { useEffect, useState, useContext } from "react";
import Tile from "../components/Tile";
import "../App.css";
import { useNavigate } from "react-router-dom";
import { SzakokContext } from "../context/SzakokContext";
import { UserContext } from "../context/UserContext";  // UserContext importálása
import SzakokFinder from "../apis/SzakokFinder";
import { v4 as uuidv4 } from "uuid";
import "./SzakjelentesLetrehozas.css"; 

const getLocalDateTime = () => {
    const date = new Date();
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60 * 1000);
    return localDate.toISOString().slice(0, 16);
};

function SzakjelentesLetrehozas() {
    const { szakfelelosok, setSelectedSzakfelelos, setSzakok } = useContext(SzakokContext);
    const { userData } = useContext(UserContext);  // A bejelentkezett felhasználó adatai a UserContext-ből
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    // Oldal automatikus újratöltése a komponens első betöltésekor
    useEffect(() => {
        if (!sessionStorage.getItem('refreshed')) {
            sessionStorage.setItem('refreshed', 'true');
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        // Ellenőrizzük, hogy van-e bejelentkezett felhasználó, majd szűrjük az adatokat
        if (userData && userData.name) {
            const filtered = szakfelelosok.filter((item) => item.szakfelelos_neve === userData.name);
            setFilteredData(filtered);
        }
    }, [userData, szakfelelosok]);

    const handleTileClick = async (szakfelelos) => {
        const newSzakId = uuidv4();
        

        const newSzakData = {
            szak_id: newSzakId,
            name: szakfelelos.kepzes_neve,
            szak_kod: szakfelelos.szak_kod,
            szakfelelos_nev: szakfelelos.szakfelelos_neve,
            modosito_neve: userData.name,  // Dinamikusan a bejelentkezett felhasználó neve
            felev: generateFelevOptions()[2],
            status: 1,
            modositas_datuma: getLocalDateTime(),
        };

        try {
            // Adatok beszúrása a háttérben
            const response = await SzakokFinder.post("/szakok", newSzakData);
            setSzakok((prevSzakok) => [...prevSzakok, response.data]);
            setSelectedSzakfelelos(szakfelelos);

            // Átirányítás az alapadatok oldalra ("/alapadatok")
            navigate(`/szakjelentesek/${newSzakId}/alapadatok`);
        } catch (err) {
            console.error("Nem sikerült létrehozni az új szakot:", err);
        }
    };

    const generateFelevOptions = () => {
        const currentYear = new Date().getFullYear();
        const options = [];
        for (let i = -2; i <= 2; i++) {
            const year1 = currentYear + i;
            const year2 = (currentYear + i + 1).toString().slice(-2);
            options.push(`${year1}/${year2}`);
        }
        return options;
    };

    return (
        <div className="szakjelentes-letrehozas">
            <div className="header">
                <h1>Szakjelentés létrehozása</h1>
            </div>
            <div className="tiles">
                {filteredData.map((item) => (
                    <Tile
                        key={item.id}
                        icon={<i className="material-icons">add</i>}
                        // Frissítve, hogy megjelenítse mind a képzés nevét, mind a képzési szintet
                        text={`${item.kepzes_neve} - ${item.kepzesi_szint}`} 
                        onClick={() => handleTileClick(item)}
                    />
                ))}
            </div>
        </div>
    );
}

export default SzakjelentesLetrehozas;

import React, { useState, useEffect, createContext } from 'react';
import { jwtDecode } from 'jwt-decode';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    // Ellenőrizzük, hogy van-e token a localStorage-ban
    const token = localStorage.getItem('token');
    if (token) {
      try {
        // Token dekódolása és felhasználói adatok beállítása
        const decoded = jwtDecode(token);
        setUserData({
          name: decoded.name,
          neptun: decoded.neptun,
          token: token
        });
      } catch (err) {
        console.error('Érvénytelen token:', err);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './AdminOptions.css'; // Importáljuk a CSS fájlt

const AdminOptions = () => {
    const { szakKod } = useParams(); 
    const [options, setOptions] = useState([]);
    const [newOption, setNewOption] = useState('');
    const [selectedTable, setSelectedTable] = useState('munkarend_options');
    const [szakNev, setSzakNev] = useState(''); // Új állapot a szaknév tárolására

    useEffect(() => {
        fetchSzakNev(); // Lekérjük a szaknevet
        fetchOptions(); // Lekérjük az opciókat
    }, [selectedTable, szakKod]);

    const fetchSzakNev = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/szakfelelosok/kod/${szakKod}`);
            if (response.data && response.data.status === "success") {
                setSzakNev(response.data.data.szak_neve);
            } else {
                setSzakNev(szakKod); // Ha nem található, akkor a szakkódot jelenítjük meg
            }
        } catch (err) {
            console.error('Failed to fetch szak név:', err);
            setSzakNev(szakKod); // Hiba esetén is a szakkódot jelenítjük meg
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/${selectedTable}/${szakKod}`);
            setOptions(response.data);
        } catch (err) {
            console.error('Failed to fetch options:', err);
        }
    };

    const handleAddOption = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/${selectedTable}/${szakKod}`, {
                value: newOption,
                szakKod: szakKod
            });
            setOptions([...options, response.data]);
            setNewOption('');
        } catch (err) {
            console.error('Failed to add option:', err);
        }
    };

    const handleUpdateOption = async (id, newValue) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/${selectedTable}/${szakKod}/${id}`, {
                value: newValue,
                szakKod: szakKod
            });
            setOptions(options.map(option => (option.id === id ? response.data : option)));
        } catch (err) {
            console.error('Failed to update option:', err);
        }
    };

    const handleDeleteOption = async (id) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/${selectedTable}/${szakKod}/${id}`);
            setOptions(options.filter(option => option.id !== id));
        } catch (err) {
            console.error('Failed to delete option:', err);
        }
    };

    return (
        <div className="admin-options-container">
            <h1 className="admin-title">Admin Options Management - Szak: {szakNev}</h1>
            <div className="select-container">
                <label className="select-label">Select Option Table:</label>
                <select className="select-table" value={selectedTable} onChange={(e) => setSelectedTable(e.target.value)}>
                    <option value="munkarend_options">Munkarend Options</option>
                    <option value="kepzesi_forma_options">Képzési Forma Options</option>
                    <option value="specializacio_options">Specializáció Options</option>
                    <option value="engedelyszam_options">Engedélyszám Options</option>
                    <option value="szakterulet_options">Szakterület Options</option>
                    <option value="ekkr_szint_options">EKKR Szint Options</option>
                    <option value="kreditszam_options">Kreditszám Options</option>
                    <option value="gondozoi_egysegek_options">Gondozói Egységek Options</option>
                    <option value="szak_kod_options">Szak Kód Options</option>
                </select>
            </div>
            <h2 className="manage-title">Manage {selectedTable.replace('_options', '').replace('_', ' ').toUpperCase()}</h2>
            <ul className="options-list">
                {options.map(option => (
                    <li key={option.id} className="option-item">
                        <input
                            type="text"
                            className="option-input"
                            value={option.value}
                            onChange={(e) => handleUpdateOption(option.id, e.target.value)}
                        />
                        <button className="delete-button" onClick={() => handleDeleteOption(option.id)}>Delete</button>
                    </li>
                ))}
            </ul>
            <div className="new-option-container">
                <input
                    type="text"
                    className="new-option-input"
                    value={newOption}
                    onChange={(e) => setNewOption(e.target.value)}
                    placeholder="New Option"
                />
                <button className="add-button" onClick={handleAddOption}>Add Option</button>
            </div>
        </div>
    );
};

export default AdminOptions;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSzakfelelosById } from "../apis/SzakokFinder"; // API hívás az adatok lekérésére
import "../routes/AlapadatokView.css";
import SideTile from "../components/SideTile";

const AlapadatokView = () => {
    const { szakId } = useParams();
    const [alapadatok, setAlapadatok] = useState(null); // A változó kezdetben null értékű
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAlapadatok = async () => {
            try {
                const alapadatokResponse = await getSzakfelelosById(szakId); // Adatok lekérése a megfelelő API végpontból
                setAlapadatok(alapadatokResponse.data.data);
            } catch (error) {
                console.error("Failed to fetch alapadatok:", error);
            }
        };

        fetchAlapadatok();
    }, [szakId]);

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`);
    };

    return (
        <div className="alapadatok-view uj-szak-page">
            <div className="uj-szak-container">
                <h1>Szak alapadatok</h1>
                {!alapadatok ? (
                    <p>Loading...</p>
                ) : (
                    <div className="card">
                        <div><strong>Képzés neve:</strong> <span>{alapadatok.kepzes_neve}</span></div>
                        <div><strong>Képzési szint:</strong> <span>{alapadatok.kepzesi_szint}</span></div>
                        <div><strong>Képzési terület:</strong> <span>{alapadatok.kepzesi_terulet}</span></div>
                        <div><strong>Kreditszám:</strong> <span>{alapadatok.kreditszam}</span></div>
                        <div><strong>Munkarend:</strong> <span>{alapadatok.munkarend && alapadatok.munkarend.join(", ")}</span></div>
                        <div><strong>Képzési forma:</strong> <span>{alapadatok.kepzesi_forma}</span></div>
                        <div><strong>Specializációk:</strong> <span>{alapadatok.specializaciok && alapadatok.specializaciok.join(", ")}</span></div>
                        <div><strong>Engedélyszám:</strong> <span>{alapadatok.engedelyszam}</span></div>
                        <div><strong>Szak indításának első tanéve:</strong> <span>{alapadatok.szak_inditas && alapadatok.szak_inditas.join(", ")}</span></div>
                        <div><strong>Képzést gondozó kar:</strong> <span>{alapadatok.kepzest_gondozo_kar}</span></div>
                        <div><strong>Képzést gondozó tanszék:</strong> <span>{alapadatok.kepzest_gondozo_tanszek}</span></div>
                        <div><strong>Szakfelelős neve:</strong> <span>{alapadatok.szakfelelos_neve}</span></div>
                    </div>
                )}
                
                <SideTile id={szakId} texts={[
                    "Szak alapadatok",            
                    "A képzés",
                    "A szak mintatanterve",
                    "Tehetséges, kiemelkedő hallgatói teljesítmények",
                    "Idegen nyelvi követelmények",
                    "Kötelező szakmai gyakorlat",
                    "Mintatanterv fejlesztések, módosítások",
                    "Fejlesztési javaslatok",
                    "Oktatás",
                    "Szakra vonatkozó adatok",
                    "Versenytárs elemzés",
                    "Meghívott előadók és óraadók",
                    "A képzés tanulmányi körülményei",
                    "A szak kommunikációja",
                    "Szakjelentés lezárása"
                ]}
                /> 
                <div className="szak-button-container">
                    <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                </div>
            </div>
        </div>
    );
};

export default AlapadatokView;

import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../App.css";
import SzakokFinder from '../apis/SzakokFinder';  // API hívás
import { UserContext } from '../context/UserContext';  // Felhasználói adatok context

function NavBar({ sidebar, setSidebar, responsive }) {
    const { userData, setUserData } = useContext(UserContext);  // Felhasználói adatok context-ből
    const [submenuOpen, setSubmenuOpen] = useState(false);
    const [szakok, setSzakok] = useState([]);  // Szakok állapot kezelése
    const [isLoading, setIsLoading] = useState(true); // Betöltési állapot
    const navigate = useNavigate();  // Navigációs hook

    // Lekérjük a bejelentkezett felhasználó szakjait a Neptun kódja alapján
    useEffect(() => {
        const fetchSzakok = async () => {
            try {
                // API hívás, hogy lekérje a felhasználó szakjait
                const response = await SzakokFinder.get(`/szakfelelosok/szakok/${userData.neptun}`);
                console.log("Fetched szakok:", response.data.data); // Sikeres válasz logolása
                setSzakok(response.data.data);  // Szakok betöltése az állapotba
                setIsLoading(false); // Sikeres betöltés
            } catch (error) {
                console.error("Nem sikerült lekérni a szakokat:", error);
                setIsLoading(false); // Hiba esetén is leállítjuk a betöltést
            }
        };

        if (userData && userData.neptun) {
            fetchSzakok();  // Csak akkor hívjuk meg, ha van felhasználói adat
        }
    }, [userData]);

    const showSidebar = () => setSidebar(!sidebar);

    const toggleSubmenu = (e) => {
        e.stopPropagation();
        setSubmenuOpen(!submenuOpen);
    };

    // Szak alapadatok oldalra navigálás az adott szak alapján
    const handleSubNavClick = (szakKod) => {
        try {
            console.log("Navigating to:", `/szakinformacio/${szakKod}`);  // Logoljuk a navigációs célt
            navigate(`/szakinformacio/${szakKod}`);  // Navigálás a szak információ oldalra
            showSidebar();  // Bezárjuk az oldalsávot, ha szükséges
        } catch (error) {
            console.error("Nem sikerült navigálni a szak információ oldalra:", error);
        }
    };

    // Kijelentkezés függvény
    const handleLogout = () => {
        localStorage.removeItem("token");  // Token törlése
        setUserData(null);  // Felhasználói adatok törlése a UserContext-ből
        navigate("/login");  // Átirányítás a bejelentkezési oldalra
    };

    return (
        <>
            <div className='navbar'>
                <Link to='#' className='menu-bars'>
                    <i className="material-icons" onClick={showSidebar}>menu</i>
                </Link>
                <div className='user-info'>
                    <div className='user-details'>
                        <span>{userData ? userData.name : "Ismeretlen felhasználó"}</span>  {/* Dinamikus név */}
                        <span>{userData ? userData.neptun : "Ismeretlen kód"}</span>  {/* Dinamikus Neptun kód */}
                    </div>
                    <button className="logout-button" onClick={handleLogout} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <i className="material-icons">power_settings_new</i>
                    </button>
                </div>
            </div>
            <nav className={`${sidebar ? 'nav-menu active' : 'nav-menu'} ${responsive ? 'responsive' : ''}`}>
                <ul className='nav-menu-items'>
                    <li className='navbar-toggle'>
                        <Link to='#' className='menu-bars'>
                            <i className="material-icons" onClick={showSidebar}>close</i>
                        </Link>
                    </li>
                    <li className='nav-text'>
                        <Link to='/'>
                            <i className="material-icons">home</i>
                            <span>Fő oldal</span>
                        </Link>
                    </li>
                    <li className='nav-text'>
                        <Link to='#' onClick={toggleSubmenu}>
                            <i className="material-icons">info</i>
                            <span>Szak alapadatok</span>
                        </Link>
                    </li>
                    
                    {submenuOpen && (
                        isLoading ? (
                            <li className="nav-sub-text">Betöltés...</li>  // Betöltés állapot
                        ) : (
                            szakok.length > 0 ? (
                            szakok.map((szak, index) => (
                                <li key={index} className="nav-sub-text">
                                <a href="#" onClick={() => handleSubNavClick(szak.szak_kod)}>
                                    <i className="material-icons">arrow_right</i>
                                    {/* Szak neve és képzési szint megjelenítése */}
                                    <span>{`${szak.kepzes_neve} - ${szak.kepzesi_szint}`}</span>
                                </a>
                                </li>
                            ))
                            ) : (
                            <li className="nav-sub-text">Nincs elérhető szak</li>  // Ha nincs szak
                            )
                        )
                    )}

                    <li className='nav-text'>
                        <Link to='/szakjelentesek'>
                            <i className="material-icons">description</i>
                            <span>Szakjelentések</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
}

export default NavBar;

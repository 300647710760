import React, { createContext, useState, useEffect } from "react";
import api from "../apis/api"; // Az Axios példány importálása, amely kezeli a JWT tokent

export const SzakokContext = createContext();

export const SzakokContextProvider = (props) => {
    const [szakok, setSzakok] = useState([]);
    const [selectedSzak, setSelectedSzak] = useState(null);
    const [szakfelelosok, setSzakfelelosok] = useState([]);
    const [selectedSzakfelelos, setSelectedSzakfelelos] = useState(null);

    useEffect(() => {
        const fetchSzakok = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    api.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Token beállítása a fejléchez
                }
                const response = await api.get("/szakok");  // A Szakok adatok JWT tokennel
                setSzakok(response.data.data.szakok);
            } catch (err) {
                console.error("Error fetching szakok:", err);
            }
        };

        const fetchSzakfelelosok = async () => {
            try {
                const token = localStorage.getItem('token');
                if (token) {
                    api.defaults.headers.common['Authorization'] = `Bearer ${token}`; // Token hozzáadása a Szakfelelős adatokhoz
                }
                const response = await api.get("/szakfelelosok");  // A Szakfelelős adatok JWT tokennel
                setSzakfelelosok(response.data.data.szakfelelosok);
            } catch (err) {
                console.error("Error fetching szakfelelosok:", err);
            }
        };

        fetchSzakok();
        fetchSzakfelelosok();
    }, []);

    return (
        <SzakokContext.Provider value={{
            szakok,
            setSzakok,
            selectedSzak,
            setSelectedSzak,
            szakfelelosok,
            setSzakfelelosok,
            selectedSzakfelelos,
            setSelectedSzakfelelos
        }}>
            {props.children}
        </SzakokContext.Provider>
    );
};

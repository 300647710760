import React, { useState, useEffect, useContext } from 'react';
import "./Szakjelentesek.css";
import SzakokFinder from '../apis/SzakokFinder';
import { SzakokContext } from '../context/SzakokContext';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

function Szakjelentesek() {
    const navigate = useNavigate();
    const { szakok, setSzakok } = useContext(SzakokContext);
    const { userData } = useContext(UserContext);

    // 🔁 Teljes oldal reload (de csak egyszer)
    useEffect(() => {
        if (!window.location.hash.includes('#reloaded')) {
            window.location.href = window.location.href + '#reloaded';
            window.location.reload();
        }
    }, []);

    // Egyetlen objektumban tároljuk az összes szűrési feltételt:
    const [filters, setFilters] = useState({
        status: "",
        dateFrom: "",
        dateTo: "",
        szakNev: "",
        tanEv: "",
        kepzesiSzint: "",
    });

    useEffect(() => {
        if (!userData || !userData.neptun) return;
    
        const fetchData = async () => {
            try {
                console.log("✅ userData ready, fetching szakok:", userData);
                const response = await SzakokFinder.get("/szakok");
    
                if (response.data?.data?.szakok) {
                    setSzakok(response.data.data.szakok);
                    console.log("✅ Szakok elmentve:", response.data.data.szakok);
                } else {
                    console.warn("⚠️ Az API válasza nem tartalmaz szak adatokat.");
                }
            } catch (err) {
                console.error("❌ Hiba az API hívás során:", err.message);
            }
        };
    
        fetchData();
    }, [userData, setSzakok]);
    

    const handleSzakokSelect = (szakId) => {
        console.log("Kiválasztott szak ID:", szakId);
        navigate(`/szakjelentesek/${szakId}`);
    };

    const handleCreateNewReport = () => {
        console.log("Új szakjelentés létrehozása");
        navigate('/szakjelentes_letrehozas');
    };

    // A szűrési inputok változásának kezelése
    const handleFilterChange = (e) => {
        const { name, value } = e.target;
    
        // Ha az input mező a dátumokat érinti, ellenőrizzük az érvényességét
        if (name === "dateFrom") {
            // Ha a kezdődátum nagyobb lenne a végdátumnál, nem engedjük beállítani
            if (filters.dateTo && new Date(value) > new Date(filters.dateTo)) {
                alert("A kezdődátum nem lehet nagyobb, mint a végdátum!");
                return;
            }
        } else if (name === "dateTo") {
            // Ha a végdátum kisebb lenne a kezdődátumnál, nem engedjük beállítani
            if (filters.dateFrom && new Date(value) < new Date(filters.dateFrom)) {
                alert("A végdátum nem lehet kisebb, mint a kezdődátum!");
                return;
            }
        }
    
        // Ha nincs probléma, frissítjük az állapotot
        setFilters({
            ...filters,
            [name]: value,
        });
    };
    
    const [felugyeltSzakok, setFelugyeltSzakok] = useState([]); // A szakfelelős által felügyelt szakok

useEffect(() => {
    const fetchFelugyeltSzakok = async () => {
        if (!userData?.neptun) return; // Ha nincs bejelentkezve, nem futtatjuk

        try {
            const response = await SzakokFinder.get(`/szakfelelosok/szakok/${userData.neptun}`);
            if (response.data && response.data.data) {
                setFelugyeltSzakok(response.data.data.map(szak => szak.szak_kod)); // Csak a szak_kod értékeket mentjük
            }
        } catch (error) {
            console.error("Hiba történt a szakfelelős által felügyelt szakok lekérésekor:", error);
        }
    };

    fetchFelugyeltSzakok();
}, [userData]);


    // A szaklistát a megadott szűrők alapján szűrjük
    const filteredSzakok = szakok.filter((szak) => {
        // Ellenőrizzük, hogy a státusz mező létezik és számérték
        const szakStatusz = typeof szak.status !== "undefined" ? szak.status : 0;
    
        // Ha super user, csak azokat látja, amelyek státusza legalább 3
        if (userData?.isSuperUser && szakStatusz < 3) {
            return false;
        }
    
        // Ha nem super user, akkor csak azokat a szakokat láthatja, amelyekhez tartozik szakfelelősként
            if (!userData?.isSuperUser && !felugyeltSzakok.includes(szak.szak_kod)) {
                return false;
            }

 

        // Státusz szerinti szűrés
        if (filters.status && szak.status_description !== filters.status) {
            return false;
        }
    
        // Dátum szerinti szűrés
        const szakDate = new Date(szak.modositas_datuma);
        if (filters.dateFrom && szakDate < new Date(filters.dateFrom)) {
            return false;
        }
        if (filters.dateTo && szakDate > new Date(filters.dateTo)) {
            return false;
        }
    
        // Szaknév szerinti szűrés
        if (filters.szakNev && !szak.name.toLowerCase().includes(filters.szakNev.toLowerCase())) {
            return false;
        }
    
        // Tanév szerinti szűrés
        if (filters.tanEv && !szak.felev.toLowerCase().includes(filters.tanEv.toLowerCase())) {
            return false;
        }
    
        // Képzési szint szerinti szűrés
        if (filters.kepzesiSzint && !szak.kepzesi_szint.toLowerCase().includes(filters.kepzesiSzint.toLowerCase())) {
            return false;
        }
    
        return true;
    });
    
    

    // A szűrt listát rendezhetjük dátum szerint csökkenő sorrendbe
    const sortedSzakok = [...filteredSzakok].sort(
        (a, b) => new Date(b.modositas_datuma) - new Date(a.modositas_datuma)
    );

    const [selectedSzakok, setSelectedSzakok] = useState(new Set());

    const handleCheckboxChange = (szakId) => {
        setSelectedSzakok((prev) => {
            const newSelected = new Set(prev);
            if (newSelected.has(szakId)) {
                newSelected.delete(szakId);
            } else {
                newSelected.add(szakId);
            }
            return newSelected;
        });
    };

    const handleFinalizeSelectedReports = async () => {
        if (selectedSzakok.size === 0) return;
    
        const confirmAction = window.confirm("Biztosan véglegesen lezárod a kijelölt szakjelentéseket?");
        if (!confirmAction) return;
    
        try {
            await Promise.all([...selectedSzakok].map(async (szakId) => {
                const selectedSzak = szakok.find(szak => szak.szak_id === szakId);
                
                if (!selectedSzak) return;
    
                await SzakokFinder.put(`/szakok/${szakId}`, {
                    name: selectedSzak.name, // 🔹 Ne hagyjuk üresen!
                    felev: selectedSzak.felev, // 🔹 Ne hagyjuk üresen!
                    status: 5, // Státusz 5 = véglegesen lezárt
                    modosito_neve: userData?.name || "Ismeretlen felhasználó", // 🔹 Ha nincs név, adjunk egy alapértelmezettet
                    filling_status: selectedSzak.filling_status || 1
                });
            }));
    
            alert("A kiválasztott szakjelentések véglegesen lezárva!");
            setSelectedSzakok(new Set());
            window.location.reload();
        } catch (error) {
            console.error("Hiba történt a lezárás során:", error);
            alert("Hiba történt a szakjelentések lezárásakor.");
        }
    };
    
    

    return (
    
        
        <div className="szakjelentesek">
           <div className="header-container">
                <h1>Szakjelentések</h1>
                {!userData?.isSuperUser && (
                    <button className="create-button" onClick={handleCreateNewReport}>
                        <AddIcon className="plus-icon" />
                        Új szakjelentés
                    </button>
                )}
            </div>

            {/* Szűrési mezők */}
            <div className="filters">
                <div className="filter">
                    <label>Státusz:</label>
                    <select name="status" value={filters.status} onChange={handleFilterChange}>
                        <option value="">Összes</option>
                        <option value="Feldolgozás alatt">Feldolgozás alatt</option>
                        <option value="Lezárt">Lezárt</option>
                        <option value="Véleményezés alatt">Véleményezés alatt</option>
                        <option value="Véleményezett">Véleményezett</option>
                        <option value="Véglegesítve lezárva">Véglegesítve lezárva</option>
                    </select>
                </div>
                <div className="filter">
                    <label>Dátumtól:</label>
                    <input
                        type="date"
                        name="dateFrom"
                        value={filters.dateFrom}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="filter">
                    <label>Dátumig:</label>
                    <input
                        type="date"
                        name="dateTo"
                        value={filters.dateTo}
                        onChange={handleFilterChange}
                    />
                </div>
                <div className="filter">
                    <label>Szak név:</label>
                    <input
                        type="text"
                        name="szakNev"
                        value={filters.szakNev}
                        onChange={handleFilterChange}
                        placeholder="Keresés szaknév alapján"
                    />
                </div>
                <div className="filter">
                    <label>Tanév:</label>
                    <input
                        type="text"
                        name="tanEv"
                        value={filters.tanEv}
                        onChange={handleFilterChange}
                        placeholder="Pl. 2023/24"
                    />
                </div>
                <div className="filter">
                    <label>Képzési szint:</label>
                    <select
                        name="kepzesiSzint"
                        value={filters.kepzesiSzint}
                        onChange={handleFilterChange}
                    >
                        <option value="">Összes</option>
                        <option value="BSc">BSc</option>
                        <option value="MSc">MSc</option>
                        <option value="PhD">PhD</option>
                        <option value="osztatlan">osztatlan</option>
                        <option value="szakképzés">szakképzés</option>
                        {/* Tetszés szerint bővíthető további képzési szintekkel */}
                    </select>
                </div>
            </div>
            {userData?.isSuperUser && userData?.role === 1 && selectedSzakok.size > 0 && (
                <button 
                    className="szak-button-custom finalize-button floating-bottom-right"
                    onClick={handleFinalizeSelectedReports}
                >
                    Végleges lezárás
                </button>
            )}




            {/* A szűrés és rendezés után kapott táblázat */}
            <div className="table-container">
                <table>
                <thead>
                        <tr>
                            {userData?.isSuperUser && userData?.role === 1 && <th style={{ width: "50px" }}>✔</th>}
                            <th>Szak neve</th>
                            <th>Képzési szint</th>
                            <th>Tanév</th>
                            <th>Státusz</th>
                            <th>Módosító neve</th>
                            <th>Módosítás dátuma</th>
                            <th>Művelet</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedSzakok.map((szak) => (
                            <tr 
                                key={szak.szak_id} 
                                onClick={(e) => {
                                    if (!e.target.closest('input[type="checkbox"]')) { 
                                        handleSzakokSelect(szak.szak_id);
                                    }
                                }}
                            >
                                {userData?.isSuperUser && userData?.role === 1 && (
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={selectedSzakok.has(szak.szak_id)}
                                            onChange={(e) => {
                                                e.stopPropagation();
                                                handleCheckboxChange(szak.szak_id);
                                            }}
                                        />
                                    </td>
                                )}
                                <td>{szak.name}</td>
                                <td>{szak.kepzesi_szint}</td>
                                <td>{szak.felev}</td>
                                <td>{szak.status_description}</td>
                                <td>{szak.modosito_neve}</td>
                                <td>{new Date(szak.modositas_datuma).toLocaleString("hu-HU")}</td>
                                <td>
                                    {szak.status_description === "Feldolgozás alatt" ? (
                                        <i className="material-icons">edit</i>
                                    ) : szak.status_description === "Lezárt" ? (
                                        <i className="material-icons">info</i>
                                    ) : szak.status_description === "Véleményezés alatt" ? (
                                        <i className="material-icons">rate_review</i>
                                    ) : szak.status_description === "Véleményezett" ? (
                                        <i className="material-icons">check_circle</i>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Szakjelentesek;

import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LargeTile from "../components/LargeTile";
import { SzakokContext } from '../context/SzakokContext';
import Szakfelelosok from "../data/Szakfelelosok.json"; // Import the JSON file

const SzakinfoDetails = () => {
    const { szakId } = useParams();
    const { selectedSzak, setSelectedSzak } = useContext(SzakokContext); // Define selectedSzak

    useEffect(() => {
        // Load data from JSON file based on id
        const selected = Szakfelelosok.find(szak => szak.id === szakId);
        if (selected) {
            setSelectedSzak(selected);
        }
    }, [szakId, setSelectedSzak]);

    return (
        <div className='szak-details'>
            {selectedSzak ? (
                <LargeTile text={selectedSzak.leiras} />
            ) : (
                "Loading..."
            )}
        </div>
    );
}

export default SzakinfoDetails;

import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import SzakokFinder from "../apis/SzakokFinder";
import { SzakokContext } from "../context/SzakokContext";
import "../components/Ujszak.css";

// Először definiáljuk a getLocalDateTime függvényt
const getLocalDateTime = () => {
    const date = new Date();
    return date.toLocaleString("sv-SE", {
        hour12: false,
        timeZone: "Europe/Budapest"
    }).replace(" ", "T").slice(0, 16);
};



const UjSzak = () => {
    const { szakId } = useParams();
    const { setSzakok, setSelectedSzakfelelos } = useContext(SzakokContext);
    const navigate = useNavigate();

    const generateFelevOptions = () => {
        const currentYear = new Date().getFullYear();
        const options = [];
        for (let i = -2; i <= 2; i++) {
            const year1 = currentYear + i;
            const year2 = (currentYear + i + 1).toString().slice(-2);
            options.push(`${year1}/${year2}`);
        }
        return options;
    };

    const [selectedSzak, setSelectedSzak] = useState({
        szak_id: szakId,
        name: "",
        szak_kod: "",
        szakfelelos_nev: "",
        modosito_neve: "",
        felev: generateFelevOptions()[2],
        status: 1,
        modositas_datuma: getLocalDateTime(), // A helyi idővel inicializáljuk a modositas_datuma-t
    });

    useEffect(() => {
        const storedSzakfelelos = localStorage.getItem('selectedSzakfelelos');
        if (storedSzakfelelos) {
            const szakfelelos = JSON.parse(storedSzakfelelos);
            setSelectedSzakfelelos(szakfelelos);
    
            setSelectedSzak((prev) => ({
                ...prev,
                name: szakfelelos.szak_neve || "", // Biztosítja, hogy ne legyen undefined
                szak_kod: szakfelelos.szak_kod,
                szakfelelos_nev: szakfelelos.modosito_neve || "", // Itt a modosito_neve kerül megjelenítésre
                modosito_neve: szakfelelos.modosito_neve || "", // Alapértelmezett érték
            }));
        }
    }, [setSelectedSzakfelelos]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedSzak({ ...selectedSzak, [name]: value });
    };

    const handleSave = async (e) => {
        e.preventDefault();
        try {
            const updatedSzak = {
                ...selectedSzak,
                modositas_datuma: getLocalDateTime() // Helyi időzóna
            };
    
            const response = await SzakokFinder.post("/szakok", updatedSzak);
            setSzakok((prevSzakok) => [...prevSzakok, response.data]);
            navigate("/szakjelentesek");
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="uj-szak-page">
            <div className="uj-szak-container">
                <h1>Új Szak Létrehozása</h1>
                <form onSubmit={handleSave}>
                    <div className="form-group">
                        <label>Név</label>
                        <input type="text" name="name" value={selectedSzak.name} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Szak Kód</label>
                        <input type="text" name="szak_kod" value={selectedSzak.szak_kod} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Szakfelelős</label>
                        <input type="text" name="szakfelelos_nev" value={selectedSzak.szakfelelos_nev} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Módosító Neve</label>
                        <input type="text" name="modosito_neve" value={selectedSzak.modosito_neve} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Félév</label>
                        <select name="felev" value={selectedSzak.felev} onChange={handleInputChange}>
                            {generateFelevOptions().map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Státusz</label>
                        <input type="text" name="status_description" value="Feldolgozás alatt" readOnly />
                    </div>
                    <div className="form-group">
                        <label>Módosítás Dátuma</label>
                        <input type="datetime-local" name="modositas_datuma" value={selectedSzak.modositas_datuma} readOnly />
                    </div>
                    <div className="button-container">
                        <button type="submit">Mentés</button>
                    </div>
                </form>
            </div>
            <Outlet />
        </div>
    );
};

export default UjSzak;

import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { getSzakLetszamAdatok, createSzakLetszamAdatok, updateSzakLetszamAdatok } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import SideTile from "../SideTile";
import Modal from 'react-modal';
import "../../components/Ujszak.css";
import "./SzakLetszamAdatok.css";


const SzakLetszamAdatok = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();
    const sideTileRef = useRef(null);

    const [szakLetszamAdatok, setSzakLetszamAdatok] = useState({
        hallgatoi_letszam_merleg: "",
        felveteli_adatok: "",
        dpr_adatok: "",
        filling_status: 1
    });

    const [images, setImages] = useState({
        hallgato_letszam_merleg_section: [],
        felveteli_adatok_section: [],
        dpr_adatok_section: []
    });

    const [isNew, setIsNew] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    const sections = {
        hallgatoi_letszam_merleg: "hallgato_letszam_merleg_section",
        felveteli_adatok: "felveteli_adatok_section",
        dpr_adatok: "dpr_adatok_section"
    };

    const isFinalized = szakLetszamAdatok.filling_status === 3;

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');
            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };
        scrollActiveSectionIntoView();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const szakLetszamAdatokResponse = await getSzakLetszamAdatok(szakId);
                if (szakLetszamAdatokResponse.data.data.szakLetszamAdatok) {
                    setSzakLetszamAdatok(szakLetszamAdatokResponse.data.data.szakLetszamAdatok);
                    await fetchImages();
                    setIsNew(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newRecord = { ...szakLetszamAdatok, filling_status: 1 };
                    try {
                        await createSzakLetszamAdatok(szakId, newRecord);
                        window.location.reload();
                    } catch (createError) {
                        console.error("Error creating new record:", createError);
                    }
                } else {
                    console.log("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const hallgatoiImages = await fetchImagesBySection(sections.hallgatoi_letszam_merleg);
            const felveteliImages = await fetchImagesBySection(sections.felveteli_adatok);
            const dprImages = await fetchImagesBySection(sections.dpr_adatok);

            setImages({
                hallgato_letszam_merleg_section: hallgatoiImages,
                felveteli_adatok_section: felveteliImages,
                dpr_adatok_section: dprImages,
            });
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const fetchImagesBySection = async (section) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/${section}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Error fetching images for section ${section}`);
            }

            const data = await response.json();
            return data.data || [];
        } catch (error) {
            console.error(`Error fetching images for section ${section}:`, error);
            return [];
        }
    };

    const handleChange = (e) => {
        if (!isFinalized) {
            const { name, value } = e.target;
            const updatedData = { ...szakLetszamAdatok, [name]: value };
            setSzakLetszamAdatok(updatedData);
        }
    };

    const handleDrop = async (acceptedFiles, section) => {
        if (!isFinalized) {
            const formData = new FormData();
            formData.append('image', acceptedFiles[0]);
            formData.append('section', section);
    
            try {
                const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
                // Token nélküli feltöltési kérés
                const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                    method: 'POST',
                    body: formData,
                });
    
                if (!response.ok) {
                    throw new Error('Failed to upload image');
                }
    
                const uploadResponse = await response.json();
    
                if (uploadResponse && uploadResponse.image_path) {
                    const imagePath = uploadResponse.image_path.replace('uploads/', '');
                    setImages((prevImages) => ({
                        ...prevImages,
                        [section]: [...prevImages[section], { ...uploadResponse, image_path: imagePath }]
                    }));
                } else {
                    console.error('Unexpected response format:', uploadResponse);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };
    

    const handleDeleteImage = async (imageId, section) => {
        if (!isFinalized) {
            try {
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                    method: 'DELETE',
                });

                setImages((prevImages) => ({
                    ...prevImages,
                    [section]: prevImages[section].filter(img => img.id !== imageId)
                }));
            } catch (error) {
                console.error('Error deleting image:', error);
            }
        }
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...szakLetszamAdatok, filling_status: status };

        try {
            if (isNew) {
                await createSzakLetszamAdatok(szakId, updatedData);
            } else {
                await updateSzakLetszamAdatok(szakId, updatedData);
            }

            window.location.reload(); // Reload the page after save or finalize.
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/oktatas`);
    };

    const isFormValid = () => {
        return szakLetszamAdatok.hallgatoi_letszam_merleg &&
               szakLetszamAdatok.felveteli_adatok &&
               szakLetszamAdatok.dpr_adatok;
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, 3);
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const handleUndoFinalize = (e) => {
        handleSubmit(e, 2); // Set status back to 2 to allow editing again
    };

    const { getRootProps: getRootPropsHallgato, getInputProps: getInputPropsHallgato, isDragActive: isDragActiveHallgato } = useDropzone({
        onDrop: (files) => handleDrop(files, sections.hallgatoi_letszam_merleg),
        accept: 'image/*',
        disabled: isFinalized
    });

    const { getRootProps: getRootPropsFelveteli, getInputProps: getInputPropsFelveteli, isDragActive: isDragActiveFelveteli } = useDropzone({
        onDrop: (files) => handleDrop(files, sections.felveteli_adatok),
        accept: 'image/*',
        disabled: isFinalized
    });

    const { getRootProps: getRootPropsDpr, getInputProps: getInputPropsDpr, isDragActive: isDragActiveDpr } = useDropzone({
        onDrop: (files) => handleDrop(files, sections.dpr_adatok),
        accept: 'image/*',
        disabled: isFinalized
    });

    return (
        <div className="uj-szak-page">
            <SideTile ref={sideTileRef} id={szakId} texts={[
                  "Szak alapadatok",
                  "A képzés",
                  "A szak mintatanterve",
                  "Tehetséges, kiemelkedő hallgatói teljesítmények",
                  "Idegen nyelvi követelmények",
                  "Kötelező szakmai gyakorlat",
                  "Mintatanterv fejlesztések, módosítások",
                  "Fejlesztési javaslatok",
                  "Oktatás",
                  "Hallgatókra vonatkozó adatok",
                  "Versenytárs elemzés",
                  "Meghívott előadók és óraadók",
                  "A képzés tanulmányi körülményei",
                  "A szak kommunikációja",
                  "Szakjelentés lezárása"
            ]}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.4 A hallgatók felvétele, előrehaladása, tanulmányaik elismerése és a képesítés odaítélése</h1>
                <h2>Szakra vonatkozó adatok</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    {/* 9.1 Hallgatói létszám mérleg */}
                    <div className="card">
                        <h2>9.1 Hallgatói létszám mérleg</h2>
                        <p>A szak aktív  és passzív státuszban lévő hallgatóinak létszáma, az új belépők (felvettek) száma, a lemorzsolódott hallgatók számának alakulása és az abszolváltak száma. Kérjük, minimum 5 évre visszamenően elemezzék az adatokat (amennyiben rendelkezésre áll az adat).</p>
                        <p>A szak létszámára vonatkozó adatokat szűrve, képként kérjük feltölteni, majd az adatmezőben elemezni.
                            Forrás: <a href="https://seafile.sze.hu/f/385175c2b0584408b157/" target="_blank">link</a>
                        </p>
                        <div className="form-group">
                            <textarea
                                name="hallgatoi_letszam_merleg"
                                value={szakLetszamAdatok.hallgatoi_letszam_merleg || ""}
                                onChange={handleChange}
                                placeholder="Hallgatói létszám mérleg"
                                disabled={isFinalized}
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsHallgato()} className={`dropzone ${isDragActiveHallgato ? "active" : ""}`}>
                                <input {...getInputPropsHallgato()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.hallgato_letszam_merleg_section.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        {!isFinalized && (
                                            <button 
                                                type="button" 
                                                onClick={() => handleDeleteImage(image.id, 'hallgato_letszam_merleg_section')} 
                                                className="delete_image_button_small"
                                            >
                                                <i className="material-icons">delete</i>
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 9.2 Felvételi adatok */}
                    <div className="card">
                        <h2>9.2 Felvételi adatok</h2>
                        <p>
                            A szakra jelentkezők és jelentkezések, valamint a felvett hallgatók adatainak elemzése, értékelése (munkarend, finanszírozási forma, korcsoport, lakóhely, ponthatár stb.). Kérjük, minimum 5 évre visszamenően elemezzék az adatokat (amennyiben rendelkezésre áll az adat).
                            A szakra vonatkozó adatokat szűrve, képként kérjük feltölteni, majd az adatmezőben elemezni. 
                            Jelentkezőkre vonatkozó adatok forrása: <a href="https://app.powerbi.com/view?r=eyJrIjoiOGZlNDY3OTYtMzQ4OC00ZGQxLWExOTUtMTdmMmZiYzAxZjg2IiwidCI6Ijg3NTQzNjFiLWE0OWEtNDg5NC1iYzc1LWRlYTQ2YTI1MzZjMiIsImMiOjl9" target="_blank">link</a>
                            Felvettekre vonatkozó adatok forrása: <a href="https://app.powerbi.com/view?r=eyJrIjoiMzE3ZWI5MzEtN2JhZC00Yjg1LWEwM2EtMDdjNzBlYzIyYmJmIiwidCI6ImJhM2JmYTgyLTM0MzctNDZlNi04MTVjLWQ5ODc4MTRlZWFlZSIsImMiOjh9" target="_blank">link</a>
                        </p>

                        <div className="form-group">
                            <textarea
                                name="felveteli_adatok"
                                value={szakLetszamAdatok.felveteli_adatok || ""}
                                onChange={handleChange}
                                placeholder="Felvételi adatok"
                                disabled={isFinalized}
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsFelveteli()} className={`dropzone ${isDragActiveFelveteli ? "active" : ""}`}>
                                <input {...getInputPropsFelveteli()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.felveteli_adatok_section.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        {!isFinalized && (
                                            <button 
                                                type="button" 
                                                onClick={() => handleDeleteImage(image.id, 'felveteli_adatok_section')} 
                                                className="delete_image_button_small"
                                            >
                                                <i className="material-icons">delete</i>
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 9.3 DPR adatok */}
                    <div className="card">
                        <h2>9.3 Diplomás pályakövetés adatok </h2>
                        <p>
                            A szakra vonatkozó munkaerőpiaci, jövedelmi, lakóhely adatok elemzése, értékelése. Forrás: <a href="https://app.powerbi.com/view?r=eyJrIjoiMzJkOTQ1ZTktYzg1ZC00ODk3LTk3MmItNWEzYzQ2YjYxY2VjIiwidCI6ImJhM2JmYTgyLTM0MzctNDZlNi04MTVjLWQ5ODc4MTRlZWFlZSIsImMiOjh9" target="_blank">link</a> segítségével. Képként kérjük feltölteni, majd az adatmezőben elemezni.
                        </p>

                        <div className="form-group">
                            <textarea
                                name="dpr_adatok"
                                value={szakLetszamAdatok.dpr_adatok || ""}
                                onChange={handleChange}
                                placeholder="DPR adatok"
                                disabled={isFinalized}
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsDpr()} className={`dropzone ${isDragActiveDpr ? "active" : ""}`}>
                                <input {...getInputPropsDpr()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.dpr_adatok_section.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        {!isFinalized && (
                                            <button 
                                                type="button" 
                                                onClick={() => handleDeleteImage(image.id, 'dpr_adatok_section')} 
                                                className="delete_image_button_small"
                                            >
                                                <i className="material-icons">delete</i>
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={isFinalized}>Mentés</button>
                        {!isFinalized ? (
                            <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>Véglegesítés</button>
                        ) : (
                            <button className="szak-button finalize-button" type="button" onClick={handleUndoFinalize}>Véglegesítés visszavonása</button>
                        )}
                    </div>
                </form>
            </div>
            <Modal
                isOpen={!!selectedImage} 
                onRequestClose={() => setSelectedImage(null)}
                contentLabel="Kép nagyítása"
                className="image-modal"
                overlayClassName="image-modal-overlay"
            >
                {selectedImage && (
                    <div>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${selectedImage}`} alt="Nagyított kép" className="modal-image"/>
                        <button onClick={() => setSelectedImage(null)} className="modal-close-button">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};
//minta

export default SzakLetszamAdatok;

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { UserContext } from '../context/UserContext';

const PrivateRoute = ({ children }) => {
  const { userData, setUserData } = useContext(UserContext);

  // Token ellenőrzése a localStorage-ból
  const token = userData?.token || localStorage.getItem('token');

  console.log("PrivateRoute ellenőrzés:", token);

  if (!token) {
    console.log("Nincs token, átirányítás a /login oldalra");
    return <Navigate to="/login" />;
  }

  try {
    // Token dekódolása a lejárati idő ellenőrzéséhez
    const decoded = jwtDecode(token);
    const tokenExpiration = decoded.exp * 1000; // Átalakítás milliszekundumra
    const isTokenExpired = Date.now() > tokenExpiration;

    if (isTokenExpired) {
      console.log("Token lejárt, átirányítás a /login oldalra");
      setUserData(null); // Felhasználói adatok törlése
      localStorage.removeItem('token'); // Token törlése
      localStorage.removeItem('refreshToken'); // Refresh token törlése
      return <Navigate to="/login" />;
    }

    // Ha a token nem járt le, engedélyezzük a hozzáférést
    return children;

  } catch (error) {
    console.error("Hiba a token dekódolása során:", error);
    localStorage.removeItem('token'); // Hibás token esetén töröljük
    localStorage.removeItem('refreshToken');
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;

import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { getVersenytarsElemzes, createVersenytarsElemzes, updateVersenytarsElemzes } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import SideTile from "../SideTile";
import Modal from 'react-modal';
import "../../components/Ujszak.css"; 
import "./VersenytarsElemzes.css"; 


const VersenytarsElemzes = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();
    const sideTileRef = useRef(null);  // Reference for the SideTile container

    const [versenytarsElemzes, setVersenytarsElemzes] = useState({
        magyar: "",
        magyar_image: "",
        nemzetkozi: "",
        nemzetkozi_image: "",
        filling_status: 1
    });

    const [images, setImages] = useState({
        magyar_section: [],
        nemzetkozi_section: []
    });

    const [isNew, setIsNew] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [saveTimeout, setSaveTimeout] = useState(null);

    const sections = {
        magyar: "magyar_section",
        nemzetkozi: "nemzetkozi_section"
    };

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');

            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };

        scrollActiveSectionIntoView();
    }, []); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const versenytarsElemzesResponse = await getVersenytarsElemzes(szakId);
                if (versenytarsElemzesResponse.data.data.versenytarsElemzes) {
                    setVersenytarsElemzes(versenytarsElemzesResponse.data.data.versenytarsElemzes);
                    await fetchImages();
                    setIsNew(false);
                } else {
                    // Automatically set filling_status to 1 and save it if there's no data yet
                    await createVersenytarsElemzes(szakId, { ...versenytarsElemzes, filling_status: 1 });
                    window.location.reload();  // Reload page after record creation
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // Ha 404-es hiba lép fel, új bejegyzés létrehozása filling_status: 1-gyel
                    const newRecord = { ...versenytarsElemzes, filling_status: 1 };
                    try {
                        await createVersenytarsElemzes(szakId, newRecord);
                        window.location.reload();  // Frissítjük az oldalt a sikeres létrehozás után
                    } catch (createError) {
                        console.error("Error creating new record:", createError);
                    }
                } else {
                    console.log("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const magyarImages = await fetchImagesBySection(sections.magyar);
            const nemzetkoziImages = await fetchImagesBySection(sections.nemzetkozi);

            setImages({
                magyar_section: magyarImages,
                nemzetkozi_section: nemzetkoziImages,
            });
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const fetchImagesBySection = async (section) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/${section}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`Error fetching images for section ${section}`);
            }

            const data = await response.json();
            return data.data || [];
        } catch (error) {
            console.error(`Error fetching images for section ${section}:`, error);
            return [];
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...versenytarsElemzes, [name]: value };
        setVersenytarsElemzes(updatedData);
    };

    const handleDrop = async (acceptedFiles, section) => {
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', section);
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => ({
                    ...prevImages,
                    [section]: [...prevImages[section], { ...uploadResponse, image_path: imagePath }]
                }));
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    

    const handleDeleteImage = async (imageId, section) => {
        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });

            setImages((prevImages) => ({
                ...prevImages,
                [section]: prevImages[section].filter(img => img.id !== imageId)
            }));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...versenytarsElemzes, filling_status: status };

        try {
            if (isNew) {
                await createVersenytarsElemzes(szakId, updatedData);
            } else {
                await updateVersenytarsElemzes(szakId, updatedData);
            }

            window.location.reload();  // Reload the page after save or finalize
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/meghivott-eloadok`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/szak-letszam-adatok`);
    };

    const isFormValid = () => {
        return versenytarsElemzes.magyar && versenytarsElemzes.nemzetkozi;
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, 3); // Véglegesítés
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const handleRevertFinalize = async (e) => {
        e.preventDefault();
        const updatedData = { ...versenytarsElemzes, filling_status: 2 };

        try {
            await updateVersenytarsElemzes(szakId, updatedData);
            window.location.reload();  // Reload the page after reverting finalize
        } catch (error) {
            console.error("Error reverting finalize:", error);
            alert("Error reverting finalize.");
        }
    };

    const { getRootProps: getRootPropsMagyar, getInputProps: getInputPropsMagyar, isDragActive: isDragActiveMagyar } = useDropzone({
        onDrop: (files) => handleDrop(files, sections.magyar),
        accept: 'image/*',
        disabled: versenytarsElemzes.filling_status === 3  // Disable dropzone if finalized
    });

    const { getRootProps: getRootPropsNemzetkozi, getInputProps: getInputPropsNemzetkozi, isDragActive: isDragActiveNemzetkozi } = useDropzone({
        onDrop: (files) => handleDrop(files, sections.nemzetkozi),
        accept: 'image/*',
        disabled: versenytarsElemzes.filling_status === 3  // Disable dropzone if finalized
    });

    return (
        <div className="uj-szak-page">
            <div className="uj-szak-container">
                <h1>10 Versenytárs elemzés</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>10.1 Magyarországi versenytársak</h2>
                        <p>
                            A hazai versenytárs intézmények jelentkezési és felvett hallgatói létszám adatainak elemzése, értékelése. A szak hazai versenytárs intézményeinek jelentkezési és felvételi adatait használhatják az elemzéshez. Kérjük, minimum 5 évre visszamenően, az adatokat táblázatosan és/vagy grafikon bemutatva, képként feltöltve, majd a szöveges értékeléssel elemezzék az adatokat. Forrás: <a href="https://app.powerbi.com/view?r=eyJrIjoiOGZlNDY3OTYtMzQ4OC00ZGQxLWExOTUtMTdmMmZiYzAxZjg2IiwidCI6Ijg3NTQzNjFiLWE0OWEtNDg5NC1iYzc1LWRlYTQ2YTI1MzZjMiIsImMiOjl9" target="_blank">link</a>
                        </p>
                        <div className="form-group">
                            <textarea
                                name="magyar"
                                value={versenytarsElemzes.magyar}
                                onChange={handleChange}
                                placeholder="Magyarországi versenytársak"
                                disabled={versenytarsElemzes.filling_status === 3}  // Disable if finalized
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsMagyar()} className={`dropzone ${isDragActiveMagyar ? "active" : ""}`}>
                                <input {...getInputPropsMagyar()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.magyar_section.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id, 'magyar_section')} 
                                            className="delete_image_button_small"
                                            disabled={versenytarsElemzes.filling_status === 3}  // Disable if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h2>10.2 Nemzetközi benchmark</h2>
                        <p>
                            A szak szempontjából nemzetközi szinten meghatározó egyetemek felsorolása (pl. különféle ranglisták alapján). A <a href="https://edurank.org/" target="_blank">https://edurank.org/</a> oldal használatával, a szakra vonatkozó adatok felhasználásával kérjük a szöveges elemzést a nemzetközi versenytárs intézményekről. Kérjük mutassa be, milyen lehetőséget lát az együttműködésre (nyári egyetem, hallgatói, oktatói mobilitás, stb.).
                            Partnerek felsorolása (pl. angol nyelvű képzésen, double degree, joint degree esetén).
                        </p>
                        <div className="form-group">
                            <textarea
                                name="nemzetkozi"
                                value={versenytarsElemzes.nemzetkozi}
                                onChange={handleChange}
                                placeholder="Nemzetközi benchmark"
                                disabled={versenytarsElemzes.filling_status === 3}  // Disable if finalized
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsNemzetkozi()} className={`dropzone ${isDragActiveNemzetkozi ? "active" : ""}`}>
                                <input {...getInputPropsNemzetkozi()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.nemzetkozi_section.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id, 'nemzetkozi_section')} 
                                            className="delete_image_button_small"
                                            disabled={versenytarsElemzes.filling_status === 3}  // Disable if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={versenytarsElemzes.filling_status === 3}>Mentés</button>
                        {versenytarsElemzes.filling_status === 3 ? (
                            <button className="szak-button finalize-button" type="button" onClick={handleRevertFinalize}>Véglegesítés visszavonása</button>
                        ) : (
                            <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>Véglegesítés</button>
                        )}
                    </div>
                </form>
            </div>

            <SideTile 
                ref={sideTileRef}
                id={szakId} 
                texts={[
                    "Szak alapadatok",
                    "A képzés",
                    "A szak mintatanterve",
                    "Tehetséges, kiemelkedő hallgatói teljesítmények",
                    "Idegen nyelvi követelmények",
                    "Kötelező szakmai gyakorlat",
                    "Mintatanterv fejlesztések, módosítások",
                    "Fejlesztési javaslatok",
                    "Oktatás",
                    "Hallgatókra vonatkozó adatok",
                    "Versenytárs elemzés",
                    "Meghívott előadók és óraadók",
                    "A képzés tanulmányi körülményei",
                    "A szak kommunikációja",
                    "Szakjelentés lezárása"
                ]}
            />

            <Modal
                isOpen={!!selectedImage} 
                onRequestClose={() => setSelectedImage(null)} 
                contentLabel="Kép nagyítása"
                className="image-modal"
                overlayClassName="image-modal-overlay"
            >
                {selectedImage && (
                    <div>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${selectedImage}`} alt="Nagyított kép" className="modal-image"/>
                        <button onClick={() => setSelectedImage(null)} className="modal-close-button">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default VersenytarsElemzes;

import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router";
import { SzakokContext } from "../context/SzakokContext";
import { UserContext } from "../context/UserContext"; 
import SzakokFinder from '../apis/SzakokFinder';
import Modal from 'react-modal';
import Dropzone from 'react-dropzone';
import "./SzakjelentesekDetailsPage.css";
import SideTile from "../components/SideTile";
import PDFViewer from '../components/PDFViewer';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import "../components/Ujszak.css";

Modal.setAppElement('#root');
 
const SzakjelentesekDetailsPage = () => {
    const { szakId } = useParams();
    const { selectedSzak, setSelectedSzak } = useContext(SzakokContext);
    const { userData } = useContext(UserContext);
    const navigate = useNavigate();

    const [editableSzak, setEditableSzak] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [pdfPreview, setPdfPreview] = useState(null);
    const [attachedPdfs, setAttachedPdfs] = useState([]);
    const [szakfelelosNev, setSzakfelelosNev] = useState("");
    const [statusData, setStatusData] = useState({});
    const [isFinalizeButtonDisabled, setIsFinalizeButtonDisabled] = useState(true);
    const [uploadedOpinions, setUploadedOpinions] = useState([]);
    const [isSideTileVisible, setIsSideTileVisible] = useState(true);

    const [opinions, setOpinions] = useState([]); // Vélemények listája
    const [newOpinion, setNewOpinion] = useState(""); // Új vélemény beviteli mező állapota

    const sideTileRef = useRef(null);   

    const isLocked = editableSzak?.status === 5;

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');
            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };
        const timeoutId = setTimeout(scrollActiveSectionIntoView, 100);
        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                console.log("Fetching szak adat:", szakId);
                const response = await SzakokFinder.get(`/szakok/${szakId}`);
                const szakData = response.data.data;
                console.log("Fetched szakData:", szakData);
    
                if (szakData) {
                    setSelectedSzak(szakData);
                    setEditableSzak(szakData);
    
                    // Szakfelelős adat lekérése
                    const szakfelelosResponse = await SzakokFinder.get(`/szakfelelosok/szakkod/${szakData.szak_kod}`);
                    if (szakfelelosResponse.data.status === "success" && szakfelelosResponse.data.data) {
                        setSzakfelelosNev(szakfelelosResponse.data.data.szakfelelos_neve);
                    }
    
                    // Vélemények lekérése
                    const opinionsResponse = await SzakokFinder.get(`/velemenyek/${szakId}`);
                    console.log("Opinions fetched:", opinionsResponse.data.data);
    
                    // Ellenőrizzük a felhasználó Neptun-kódját és nevezzük át a username-t helyesen
                    const updatedOpinions = opinionsResponse.data.data.map(opinion => ({
                        ...opinion,
                        username: opinion.username || (opinion.neptun === userData.neptun ? userData.name : "Ismeretlen felhasználó")
                    }));
    
                    // **Ellenőrizzük, hogy nincs-e duplikáció a fetch után**
                    const uniqueOpinions = updatedOpinions.filter(
                        (opinion, index, self) =>
                            index === self.findIndex((op) => op.id === opinion.id)
                    );
    
                    setOpinions(uniqueOpinions);
                }
    
                // PDF-ek lekérése
                const pdfResponse = await SzakokFinder.get(`/api/pdf/szakok/${szakId}/pdfs`);
                setAttachedPdfs(pdfResponse.data.data);
    
            } catch (err) {
                console.error("API Error:", err);
            }
        };
    
        fetchData();
    }, [szakId, setSelectedSzak, userData]);
    
    
    

    useEffect(() => {
        const fetchStatusData = async () => {
            try {
                const response = await SzakokFinder.get(`/szakjelentes_letrehozas/${szakId}/status`);
                console.log("Status data response:", response.data.data);
                const rawStatusData = response.data.data;

                const requiredStatuses = [
                    'Fejlesztési javaslatok',
                    'Idegen nyelvi követelmények',
                    'Képzés célja',
                    'Kötelező szakmai gyakorlat',
                    'Meghívott előadók és óraadók',
                    'Mintatanterv fejlesztések, módosítások',
                    'Oktatás',
                    'Szak kommunikációja',
                    'Szak létszám adatok',
                    'Szak mintaterve',
                    'Tanulmányi körülmények',
                    'Tehetséges hallgatók',
                    'Versenytárs elemzés'
                ];

                const allStatusesAreThree = requiredStatuses.every(
                    (status) => rawStatusData[status] === 3
                );

                console.log("All required statuses are 3:", allStatusesAreThree);
                setIsFinalizeButtonDisabled(!allStatusesAreThree);
                setStatusData(rawStatusData);
            } catch (error) {
                console.error("Error fetching status data:", error);
            }
        };

        fetchStatusData();
    }, [szakId]);

    const handleFinalize = async () => {
        if (isFinalizeButtonDisabled) {
            console.log("Finalize button is disabled, action prevented.");
            return;
        }
    
        try {
            const updatedSzak = { ...editableSzak, filling_status: 3, status: 2 };
            console.log("Finalizing szak with data:", updatedSzak);
            await SzakokFinder.put(`/szakok/${szakId}`, updatedSzak);
    
            setSelectedSzak(updatedSzak);
            setEditableSzak(updatedSzak);
            setIsFinalizeButtonDisabled(true); // 🔹 GOMB INAKTÍVVÁ TÉTELE
            
            alert("Szakjelentés lezárva!");
        } catch (err) {
            console.error("Error finalizing szak:", err);
        }
    };
    

    // Szakjelentés véleményezése (status = 3)
    const handleOpinion = async () => {
        try {
            const updatedSzak = { ...editableSzak, status: 3 };
            console.log("Setting szak status to 3 for véleményezés:", updatedSzak);
            await SzakokFinder.put(`/szakok/${szakId}`, updatedSzak);
            setSelectedSzak(updatedSzak);
            setEditableSzak(updatedSzak);
            alert("Szakjelentés véleményezése elérhető! A status állapota: 3");
        } catch (err) {
            console.error("Error updating szak status for opinion:", err);
        }
    };

    const handleOpinionSubmit = async () => {
        if (newOpinion.trim() === "") return; // Üres véleményeket ne küldjük be
        
        try {
            console.log("Submitting new opinion:", newOpinion);
            const token = localStorage.getItem("token");
    
            const username = userData?.name || szakfelelosNev; // Ha nincs userData.name, akkor a szakfelelős neve
    
            const response = await SzakokFinder.post(
                `/velemenyek/${szakId}`,
                { content: newOpinion, username }, // Mindig elküldjük a nevet!
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json"
                    }
                }
            );
    
            console.log("New opinion response:", response.data.data);
            
            setOpinions((prevOpinions) => {
                // **Ellenőrizzük, hogy már szerepel-e az új vélemény**
                if (prevOpinions.some(op => op.id === response.data.data.id)) {
                    return prevOpinions;
                }
                return [...prevOpinions, response.data.data]; // Ha nincs benne, hozzáadjuk
            });
    
            setNewOpinion("");
        } catch (err) {
            console.error("Nem sikerült menteni a véleményt:", err);
        }
    };
    
    
    
    
    

    const handleOpinionDelete = async (opinionId) => {
        try {
            console.log("Deleting opinion with id:", opinionId);
            await SzakokFinder.delete(`/velemenyek/${opinionId}`);
            setOpinions(opinions.filter((opinion) => opinion.id !== opinionId));
        } catch (err) {
            console.error("Nem sikerült törölni a véleményt:", err);
        }
    };

    const handleGeneratePDF = async () => {
        console.log("handleGeneratePDF triggered");
        setIsSideTileVisible(false);
        console.log("SideTile visibility set to false");
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/api/pdf/generate-pdf/${szakId}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to generate PDF');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            console.log("PDF generated, preview URL:", url);
            setPdfPreview(url);
            setModalIsOpen(true);
        } catch (err) {
            console.error('Failed to generate PDF', err);
        }
    };

    const handleSavePDF = async () => {
        if (!pdfPreview) return;
    
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(pdfPreview);
            const blob = await response.blob();
            const reader = new FileReader();
    
            reader.onloadend = async () => {
                const base64data = reader.result.split(',')[1];
                const filename = `szakjelentes_${szakId}_${Date.now()}.pdf`;
                console.log("Saving PDF with filename:", filename);
    
                const saveResponse = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/api/pdf/save-pdf/${szakId}`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({ pdfData: base64data, filename }),
                    }
                );
    
                if (!saveResponse.ok) {
                    throw new Error('Failed to save PDF');
                }
    
                const newPdf = await saveResponse.json();
                console.log("PDF saved:", newPdf);
                setAttachedPdfs([...attachedPdfs, newPdf]);
                setModalIsOpen(false);
                window.location.reload();
            };
    
            reader.readAsDataURL(blob);
        } catch (err) {
            console.error('Failed to save PDF', err);
        }
    };
    

    const handleCloseModal = () => setModalIsOpen(false);

    const handleViewPDF = (filename) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/uploads/${filename}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleViewOpinion = (filename) => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/uploads/${filename}`;
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleBack = () => navigate(`/szakjelentes_letrehozas/${szakId}/szak-kommunikacioja`);
    const handleUp = () => navigate('/szakjelentesek');

    const handleOpinionUpload = async (acceptedFiles) => {
        const formData = new FormData();
        formData.append("file", acceptedFiles[0]);

        try {
            console.log("Uploading opinion file:", acceptedFiles[0]);
            // Vélemény feltöltés
            const response = await SzakokFinder.post(`/velemenyek/${szakId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            console.log("Opinion file upload response:", response.data);
            setUploadedOpinions([...uploadedOpinions, response.data]);

            // Szak állapot frissítése (status = 3)
            await SzakokFinder.put(`/szakok/${szakId}`, {
                name: editableSzak.name,
                felev: editableSzak.felev,
                status: 3,
                modosito_neve: editableSzak.modosito_neve,
                filling_status: editableSzak.filling_status
            });

            setSelectedSzak((prev) => ({ ...prev, status: 3 }));
        } catch (error) {
            console.error("Vélemény feltöltése sikertelen:", error);
        }
    };

    // Ha még tölt, mutassuk a "Loading..." feliratot
    if (!editableSzak) {
        return <div>Loading...</div>;
    }

    // Super user ellenőrzése: csak akkor láthatja, ha status 3 vagy 4 vagy 5
    if (userData?.isSuperUser && ![3, 4, 5].includes(editableSzak.status)) {
        console.log("Super user hozzáférés megtagadva, szak status:", editableSzak.status);
        navigate("/szakjelentesek");
        return null;
    }
    

    const isFillingStatusValid = editableSzak.filling_status !== 3;
    const isPdfAvailable = attachedPdfs.length > 0;

    const handleStatusUpdate = async () => {
        try {
            let newStatus;
            
            if (editableSzak.status === 3) {
                newStatus = 4; // Véleményezés lezárása
            } else if (editableSzak.status === 4) {
                // Csak akkor engedélyezett a 4 → 5 átmenet, ha a felhasználó super user és role = 1
                if (!userData?.isSuperUser || userData?.role !== 1) {
                    console.log("Nincs jogosultság a végleges lezáráshoz.");
                    alert("Nincs jogosultság a végleges lezáráshoz.");
                    return;
                }
                newStatus = 5; // Teljes szakjelentés lezárása
            } else {
                return;
            }
    
            await SzakokFinder.put(`/szakok/${szakId}`, { ...editableSzak, status: newStatus });
    
            setSelectedSzak((prev) => ({ ...prev, status: newStatus }));
            setEditableSzak((prev) => ({ ...prev, status: newStatus }));
    
            alert(`Sikeresen frissítve: új státusz ${newStatus}`);
        } catch (err) {
            console.error("Hiba történt a státusz frissítésekor:", err);
        }
    };
    
    console.log("User Data:", userData);
console.log("Super user?", userData?.isSuperUser);
console.log("User role:", userData?.role);
console.log("Szakjelentés státusz:", editableSzak?.status);

const handleDecreaseStatus = async () => {
    if (!editableSzak) return;
    
    let newStatus = editableSzak.status - 1;
    if (newStatus < 1) {
        alert("A státusz nem lehet 0 vagy annál kisebb!");
        return;
    }

    try {
        console.log(`⚠️ Teszt: Státusz csökkentése ${editableSzak.status} → ${newStatus}`);
        await SzakokFinder.put(`/szakok/${szakId}`, { ...editableSzak, status: newStatus });
        
        setSelectedSzak((prev) => ({ ...prev, status: newStatus }));
        setEditableSzak((prev) => ({ ...prev, status: newStatus }));

        alert(`Sikeresen csökkentett státusz: ${newStatus}`);
    } catch (err) {
        console.error("Hiba történt a státusz csökkentésekor:", err);
    }
};


    return (
        <div className="szakjelentes-details uj-szak-page">
            {/* SideTile csak akkor jelenjen meg, ha NEM super user */}
            {!userData?.isSuperUser && (
                <SideTile
                    ref={sideTileRef}
                    id={szakId}
                    texts={[
                        "Szak alapadatok",
                        "A képzés",
                        "A szak mintatanterve",
                        "Tehetséges, kiemelkedő hallgatói teljesítmények",
                        "Idegen nyelvi követelmények",
                        "Kötelező szakmai gyakorlat",
                        "Mintatanterv fejlesztések, módosítások",
                        "Fejlesztési javaslatok",
                        "Oktatás",
                        "Hallgatókra vonatkozó adatok",
                        "Versenytárs elemzés",
                        "Meghívott előadók és óraadók",
                        "A képzés tanulmányi körülményei",
                        "A szak kommunikációja",
                        "Szakjelentés lezárása"
                    ]}
                    isVisible={isSideTileVisible}
                />
            )}

<div className="details-container uj-szak-container">
    <div className="button-container">
        {/* Előző szakasz gomb csak NEM super usernek */}
        {!userData?.isSuperUser && (
            <button onClick={handleBack} className="szak-button">
                Előző szakasz
            </button>
        )}
        {/* Vissza a szakjelentés listába gomb mindenkinek látható */}
        <div className="button-container">
            <button onClick={handleUp} className="szak-button">
                Vissza a szakjelentés listába
            </button>
        </div>
    </div>

    <h1>
        Szakjelentés lezárása
        {userData?.isSuperUser && editableSzak?.name && (
            <span className="szak-nev">  {editableSzak.name}</span>
        )}
    </h1>

    {/* Status alapján megjelenő gombok */}
    {!userData?.isSuperUser && (
        <div className="szak-button-container-custom">
            <button
                onClick={handleFinalize}
                className="szak-button-custom finalize-button"
                style={{
                    backgroundColor: editableSzak?.status >= 2 ? '#D3D3D3' : '', 
                    color: editableSzak?.status >= 2 ? '#808080' : '',
                    cursor: editableSzak?.status >= 2 ? 'not-allowed' : 'pointer'
                }}
                disabled={editableSzak?.status >= 2}
            >
                Teljes szakjelentés lezárása
            </button>
            <button
                onClick={handleGeneratePDF}
                className={`szak-button-custom pdf-button-custom ${isFillingStatusValid ? 'disabled' : ''}`}
                disabled={isFillingStatusValid}
            >
                PDF generálás és megjelenítés
            </button>
        </div>
    )}

    {/* TESZT GOMB: STÁTUSZ CSÖKKENTÉSE 
    <div className="test-status-button-container">
        <button onClick={handleDecreaseStatus} className="test-status-button">
            ⚠️ Teszt: Státusz Csökkentése
        </button>
    </div>
    */}
   

    <div className="attached-pdfs">
        <h3>Csatolt PDF-ek</h3>
        <ul>
            {attachedPdfs.map((pdf) => (
                <li key={pdf.id} className="pdf-item" onClick={() => handleViewPDF(pdf.filename)}>
                    <AttachFileIcon className="pdf-icon" />
                    <span className="pdf-filename">{pdf.filename}</span>
                </li>
            ))}
        </ul>
    </div>

    {/* Vélemények szekció */}
    {isPdfAvailable && (
        <div className="opinion-section">
            <h3>Vélemények</h3>

            {/* Vélemény beviteli mező */}
            <textarea
                className="opinion-input"
                placeholder="Írja be a véleményét..."
                value={newOpinion}
                onChange={(e) => setNewOpinion(e.target.value)}
                disabled={isLocked} // Ha lezárt, ne lehessen szerkeszteni
            />

            {/* Küldés gomb */}
            <button onClick={handleOpinionSubmit} className="opinion-submit-button" disabled={isLocked}>
                Vélemény hozzáadása
            </button>

             {/* Véleményezés gomb az oldal alján középen, ha a szakjelentés már lezárt (status === 2) */}
                {editableSzak?.status === 2 && (
                    <div className="centered-opinion-button">
                        <button onClick={handleOpinion} className="szak-button-custom opinion-button">
                            Beküldés vezetői véleményezésre
                        </button>
                    </div>
                )}

            {/* Megjelenített vélemények */}
            <ul className="opinions-list">
                {opinions.map((opinion) => (
                    <li key={`${opinion.id}-${opinion.created_at}`} className="opinion-item">
                        <div className="opinion-content">
                            <strong>{opinion.username}</strong>
                            <span className="opinion-date">({new Date(opinion.created_at).toLocaleString()})</span>
                            <p>{opinion.content}</p>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )}
</div>


            <Modal isOpen={modalIsOpen} onRequestClose={handleCloseModal} contentLabel="PDF Generálás Piszkozat">
                <h2>PDF Generálás Piszkozat</h2>
                <PDFViewer pdfUrl={pdfPreview} />
                {/* Mentés és Bezárás gombok (csak nem super usernek) */}
                {!userData?.isSuperUser && (
                    <>
                        <button onClick={handleSavePDF}>Mentés</button>
                        <button onClick={handleCloseModal}>Bezárás</button>
                    </>
                )}
            </Modal>
        </div>
    );
};

export default SzakjelentesekDetailsPage;

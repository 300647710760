import React, { useState, useEffect, useContext } from 'react';
import "./Szakjelentesek.css"; 
import SzakokFinder from '../apis/SzakokFinder';
import { SzakokContext } from '../context/SzakokContext';
import { UserContext } from '../context/UserContext'; // UserContext importálása
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add'; // Importáljuk a plusz ikont a Material-UI-ból

function Szakjelentesek() {
    const navigate = useNavigate();
    const { szakok, setSzakok } = useContext(SzakokContext);
    const { userData } = useContext(UserContext); // Bejelentkezett felhasználó adatai

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await SzakokFinder.get("/szakok");
                setSzakok(response.data.data.szakok);
            } catch(err) {
                console.error("Hiba az API hívás során:", err);
            }
        };
    
        fetchData();
    }, [setSzakok]);
    
    const handleSzakokSelect = (szakId) => {
        navigate(`/szakjelentesek/${szakId}`);
    }

    const handleCreateNewReport = () => {
        navigate('/szakjelentes_letrehozas');
    }

    const formatDate = (dateString) => {
        const options = { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit' 
        };
        return new Date(dateString).toLocaleDateString('hu-HU', options);
    };

    // Szűrés a bejelentkezett felhasználó szakjelentéseire
    const filteredSzakok = szakok.filter((szak) => szak.modosito_neve === userData.name);

    // Rendezés dátum szerint, csökkenő sorrendben
    const sortedSzakok = [...filteredSzakok].sort((a, b) => new Date(b.modositas_datuma) - new Date(a.modositas_datuma));

    return (
        <div className="szakjelentesek"> 
            <div className="header">
                <h1>Szakjelentések</h1>
            </div>
            <div className="button-row">  {/* Új konténer a gomb számára */}
                <button className="create-button" onClick={handleCreateNewReport}>
                    <AddIcon className="plus-icon" /> {/* Plusz ikon hozzáadása */}
                    Új szakjelentés
                </button>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>Szaknév</th> 
                            <th>Képzési szint</th> {/* Új oszlop a képzési szint számára */}
                            <th>Tanév</th> 
                            <th>Státusz</th>
                            <th>Módosító neve</th> 
                            <th>Módosítás dátuma</th>
                            <th>Művelet</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedSzakok.map((szak) => (
                            <tr onClick={() => handleSzakokSelect(szak.szak_id)} key={szak.szak_id}>
                                <td>{szak.name}</td> 
                                <td>{szak.kepzesi_szint}</td> {/* Képzési szint megjelenítése */}
                                <td>{szak.felev}</td> 
                                <td>{szak.status_description}</td>  
                                <td>{szak.modosito_neve}</td> 
                                <td>{formatDate(szak.modositas_datuma)}</td> 
                                <td>
                                    {szak.status_description === "Feldolgozás alatt" ? (
                                        <i className="material-icons">edit</i>
                                    ) : szak.status_description === "Lezárt" ? (
                                        <i className="material-icons">info</i>
                                    ) : null}
                                </td>
                            </tr>
                        ))}  
                    </tbody>
                </table>  
            </div>
        </div>
    );
}

export default Szakjelentesek;

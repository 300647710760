// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.large-tile {
  width: 75%;
  margin: 20px auto;
  min-height: 200px;
  padding: 20px;
  background-color: #44ACC9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column; /* A flex irányának beállítása oszlopra */
  align-items: center;
  justify-content: center;
  word-wrap: break-word; /* A szavak tördelésének engedélyezése */
  overflow-wrap: break-word; /* Alternatív szótördelés */
}

.large-tile p {
  margin: 0; /* Margó eltávolítása a p elemtől */
  white-space: pre-line; /* A szöveg új soroknál történő tördelése */
}
`, "",{"version":3,"sources":["webpack://./src/components/LargeTile.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,sBAAsB,EAAE,yCAAyC;EACjE,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB,EAAE,wCAAwC;EAC/D,yBAAyB,EAAE,2BAA2B;AACxD;;AAEA;EACE,SAAS,EAAE,mCAAmC;EAC9C,qBAAqB,EAAE,2CAA2C;AACpE","sourcesContent":[".large-tile {\n  width: 75%;\n  margin: 20px auto;\n  min-height: 200px;\n  padding: 20px;\n  background-color: #44ACC9;\n  border-radius: 10px;\n  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n  color: white;\n  text-align: center;\n  font-size: 18px;\n  display: flex;\n  flex-direction: column; /* A flex irányának beállítása oszlopra */\n  align-items: center;\n  justify-content: center;\n  word-wrap: break-word; /* A szavak tördelésének engedélyezése */\n  overflow-wrap: break-word; /* Alternatív szótördelés */\n}\n\n.large-tile p {\n  margin: 0; /* Margó eltávolítása a p elemtől */\n  white-space: pre-line; /* A szöveg új soroknál történő tördelése */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react";
import '../components/Tile.css'; // Importálja a CSS fájlt

function Tile({ icon, text, onClick }) {
    return (
        <div className="tile" onClick={onClick}>
            {icon}
            <p>{text}</p>
        </div>
    );
}

export default Tile;

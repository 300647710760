import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSzakMintaTerve, createSzakMintaTerve, updateSzakMintaTerve, getSzakFelelosLeirasBySzakId } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";
import Modal from 'react-modal';

const SzakMintaTerve = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [szakMintaTerve, setSzakMintaTerve] = useState({
        mintatanterv: "",
        tantargy_leiras: "",
        filling_status: 1
    });

    const [images, setImages] = useState([]);
    const [isNew, setIsNew] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);

    // Adatok lekérdezése
    useEffect(() => {
        const fetchData = async () => {
            try {
                const szakMintaTerveResponse = await getSzakMintaTerve(szakId);
                if (szakMintaTerveResponse.data.data.szakMintaTerve) {
                    setSzakMintaTerve(szakMintaTerveResponse.data.data.szakMintaTerve);
                    setIsNew(false);
                    await fetchImages();
                } else {
                    const leirasResponse = await getSzakFelelosLeirasBySzakId(szakId);
                    if (leirasResponse.data.data.leiras) {
                        setSzakMintaTerve(prevState => ({
                            ...prevState,
                            mintatanterv: leirasResponse.data.data.leiras
                        }));
                    }
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newSzakMintaTerve = { mintatanterv: "", tantargy_leiras: "", filling_status: 1 };
                    await createSzakMintaTerve(szakId, newSzakMintaTerve);
                    setSzakMintaTerve(newSzakMintaTerve);
                    setIsNew(true);
                    window.location.reload();
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    // Kép kezelés
    const fetchImages = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakok/${szakId}/images/szak_mintaterve_section`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }

            const data = await response.json();
            setImages(data.data || []);
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    // Adatok mentése
    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...szakMintaTerve, filling_status: status };

        try {
            if (isNew) {
                await createSzakMintaTerve(szakId, updatedData);
            } else {
                await updateSzakMintaTerve(szakId, updatedData);
            }
            window.location.reload();
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    // Kép feltöltés
    const handleDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        const formData = new FormData();
        formData.append("image", file);
        formData.append("section", "szak_mintaterve_section");
    
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => [
                    ...prevImages,
                    { ...uploadResponse, image_path: imagePath }
                ]);
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    

    // Kép törlése
    const handleDeleteImage = async (imageId) => {
        try {
            await fetch(`${process.env.REACT_APP_API_BASE_URL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });

            setImages((prevImages) => prevImages.filter(img => img.id !== imageId));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        accept: 'image/*',
        disabled: szakMintaTerve.filling_status === 3 // Disable if finalized
    });

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/kepzes-celja`);
    };

    const isFormValid = () => {
        return szakMintaTerve.mintatanterv && szakMintaTerve.tantargy_leiras;
    };

    const handleFinalize = async (e) => {
        if (szakMintaTerve.filling_status === 3) {
            // Véglegesítés visszavonása
            await handleSubmit(e, 2); // Set filling_status back to 2
        } else if (isFormValid()) {
            await handleSubmit(e, 3); // Véglegesítés
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.2 és 1.9 A képzési programok kialakítása, jóváhagyása, folyamatos figyelemmel kísérése és rendszeres értékelése</h1>
                <h2>2 A szak mintatanterve</h2>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>2.1 A képzés URL-címe</h2>
                        <p>Kérjük, adja meg a képzésről szóló információk elérhetőségét (weboldal linket)</p>
                        <div className="form-group">
                            <textarea
                                name="mintatanterv"
                                value={szakMintaTerve.mintatanterv}
                                onChange={(e) => setSzakMintaTerve({ ...szakMintaTerve, mintatanterv: e.target.value })}
                                placeholder="Adja meg a képzés URL-címét"
                                disabled={szakMintaTerve.filling_status === 3} // Disable if finalized
                            />
                        </div>
                    </div>

                    <div className="card">
                        <h2>2.2 Aktuális mintatanterv tábla</h2>
                        <p>Kérjük képként illessze be az aktuális mintatantervet. Az aktuális mintatanterv a <a href="https://ttr.sze.hu/#/" target="_blank" rel="noopener noreferrer">Link</a> oldalról letölthető.</p>
                        <div className="form-group">
                            <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""}`}>
                                <input {...getInputProps()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id)} 
                                            className="delete_image_button_small"
                                            disabled={szakMintaTerve.filling_status === 3} // Disable delete if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <h2>2.3 Tantárgyleírások</h2>
                        <p>Kérjük adja meg a <a href="https://ttr.sze.hu/#/" target="_blank" rel="noopener noreferrer">Link</a> oldal segítségével a tantárgylista linkjét, ahol a tantárgyleírások (tematikák) letölthetőek.</p>
                        <div className="form-group">
                            <textarea
                                name="tantargy_leiras"
                                value={szakMintaTerve.tantargy_leiras}
                                onChange={(e) => setSzakMintaTerve({ ...szakMintaTerve, tantargy_leiras: e.target.value })}
                                placeholder="Adja meg a tantárgyleírások URL-jét"
                                disabled={szakMintaTerve.filling_status === 3} // Disable if finalized
                            />
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" type="button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" type="button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={szakMintaTerve.filling_status === 3}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {szakMintaTerve.filling_status === 3 ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={!!selectedImage}
                onRequestClose={() => setSelectedImage(null)}
                contentLabel="Kép nagyítása"
                className="image-modal"
                overlayClassName="image-modal-overlay"
            >
                {selectedImage && (
                    <div>
                        <img src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${selectedImage}`} alt="Nagyított kép" className="modal-image"/>
                        <button onClick={() => setSelectedImage(null)} className="modal-close-button">
                            <i className="material-icons">close</i>
                        </button>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default SzakMintaTerve;

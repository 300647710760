import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSzakfelelosBySzakkod } from "../apis/SzakokFinder"; // API hívás a szakkód alapján
import "../routes/AlapadatokView.css";

const SzakInformacio = () => {
    const { szakkod } = useParams(); // A szakkód lekérése a paraméterekből
    const [alapadatok, setAlapadatok] = useState(null);
    const [isLoading, setIsLoading] = useState(true);  // Betöltés állapot
    const [error, setError] = useState(null);  // Hiba állapot

    useEffect(() => {
        console.log("SzakInformacio loaded with szakkod:", szakkod);  // Log a szakkódra
        const fetchAlapadatok = async () => {
            try {
                setIsLoading(true);  // Betöltés megkezdése
                const alapadatokResponse = await getSzakfelelosBySzakkod(szakkod);
                console.log("API hívás URL:", `/szakfelelosok/szakkod/${szakkod}`);  // Log az API hívott URL-re
                console.log("API válasz:", alapadatokResponse.data);  // Log az API válaszra
                
                setAlapadatok(alapadatokResponse.data);  // Beállítjuk az adatokat
            } catch (error) {
                console.error("Failed to fetch alapadatok:", error);  // Log az API hívás során fellépő hibára
                setError("Nem sikerült lekérni az adatokat.");
            } finally {
                setIsLoading(false);  // Betöltés befejezése
            }
        };

        fetchAlapadatok();
    }, [szakkod]);
    

    return (
        <div className="alapadatok-view uj-szak-page">
            <div className="uj-szak-container">
                <h1>Szak alapadatok</h1>
                {isLoading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    // Az adatmegjelenítés mindig megtörténik, ha van adat
                    <div className="card">
                        <div><strong>Képzés neve:</strong> <span>{alapadatok.kepzes_neve}</span></div>
                        <div><strong>Képzési szint:</strong> <span>{alapadatok.kepzesi_szint}</span></div>
                        <div><strong>Képzési terület:</strong> <span>{alapadatok.kepzesi_terulet}</span></div>
                        <div><strong>Kreditszám:</strong> <span>{alapadatok.kreditszam}</span></div>
                        <div><strong>Munkarend:</strong> <span>{alapadatok.munkarend && alapadatok.munkarend.join(", ")}</span></div>
                        <div><strong>Képzési forma:</strong> <span>{alapadatok.kepzesi_forma}</span></div>
                        <div><strong>Specializációk:</strong> <span>{alapadatok.specializaciok && alapadatok.specializaciok.join(", ")}</span></div>
                        <div><strong>Engedélyszám:</strong> <span>{alapadatok.engedelyszam}</span></div>
                        <div><strong>Szak indításának első tanéve:</strong> <span>{alapadatok.szak_inditas && alapadatok.szak_inditas.join(", ")}</span></div>
                        <div><strong>Képzést gondozó kar:</strong> <span>{alapadatok.kepzest_gondozo_kar}</span></div>
                        <div><strong>Képzést gondozó tanszék:</strong> <span>{alapadatok.kepzest_gondozo_tanszek}</span></div>
                        <div><strong>Szakfelelős neve:</strong> <span>{alapadatok.szakfelelos_neve}</span></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SzakInformacio;

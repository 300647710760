import React, { useState, useEffect, createContext } from 'react';
import { jwtDecode } from 'jwt-decode';

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        console.log("🔍 [FRONTEND] Token dekódolt adatok:", decoded);

        setUserData({
          name: decoded.name || "Ismeretlen felhasználó", // Már a backend küldi a helyes nevet!
          neptun: decoded.neptun || null,
          isSuperUser: decoded.isSuperUser || false,
          role: decoded.role || null,
          token: token
        });
      } catch (err) {
        console.error('❌ [FRONTEND] Érvénytelen token:', err);
        setUserData(null);
      }
    }
  }, []);

  return (
    <UserContext.Provider value={{ userData, setUserData }}>
      {children}
    </UserContext.Provider>
  );
};

import React, { useContext, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getMeghivottEloadok, createMeghivottEloadok, updateMeghivottEloadok } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import SideTile from "../SideTile";
import "../../components/Ujszak.css";
import "./MeghivottEloadok.css";

const MeghivottEloadok = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();
    const sideTileRef = useRef(null);

    const [meghivottEloadokData, setMeghivottEloadokData] = useState({
        eloadok_szoveg: "",
        filling_status: 1
    });

    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        const scrollActiveSectionIntoView = () => {
            const activeItem = document.querySelector('.item.active');
            const sideTileElement = document.querySelector('.side-tile');

            if (activeItem && sideTileElement) {
                activeItem.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
            }
        };

        scrollActiveSectionIntoView();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meghivottEloadokResponse = await getMeghivottEloadok(szakId);
                if (meghivottEloadokResponse.data.data) {
                    setMeghivottEloadokData(meghivottEloadokResponse.data.data);
                    setIsNew(false);
                } else {
                    const newRecord = { eloadok_szoveg: "", filling_status: 1 };
                    await createMeghivottEloadok(szakId, newRecord);
                    window.location.reload();  // Oldal újratöltése az új rekord létrehozása után
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    const newRecord = { eloadok_szoveg: "", filling_status: 1 };
                    await createMeghivottEloadok(szakId, newRecord);
                    window.location.reload();  // Oldal újratöltése az új rekord létrehozása után
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...meghivottEloadokData, filling_status: status };

        try {
            if (isNew) {
                await createMeghivottEloadok(szakId, updatedData);
            } else {
                await updateMeghivottEloadok(szakId, updatedData);
            }

            window.location.reload();  // Oldal újratöltése mentés vagy véglegesítés után
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/tanulmanyi-korulmenyek`);
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/versenytars-elemzes`);
    };

    const isFormValid = () => {
        return meghivottEloadokData.eloadok_szoveg.trim() !== "";
    };

    const handleFinalize = (e) => {
        if (isFormValid()) {
            handleSubmit(e, 3);  // Call handleSubmit with status 3 (finalize)
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const handleRevertFinalize = async (e) => {
        e.preventDefault();
        const updatedData = { ...meghivottEloadokData, filling_status: 2 };

        try {
            await updateMeghivottEloadok(szakId, updatedData);
            window.location.reload();  // Reload the page after reverting finalize
        } catch (error) {
            console.error("Error reverting finalize:", error);
            alert("Error reverting finalize.");
        }
    };

    return (
        <div className="uj-szak-page">
            <SideTile ref={sideTileRef} id={szakId} texts={[
                "Szak alapadatok",
                "A képzés",
                "A szak mintatanterve",
                "Tehetséges, kiemelkedő hallgatói teljesítmények",
                "Idegen nyelvi követelmények",
                "Kötelező szakmai gyakorlat",
                "Mintatanterv fejlesztések, módosítások",
                "Fejlesztési javaslatok",
                "Oktatás",
                "Hallgatókra vonatkozó adatok",
                "Versenytárs elemzés",
                "Meghívott előadók és óraadók",
                "A képzés tanulmányi körülményei",
                "A szak kommunikációja",
                "Szakjelentés lezárása"
            ]}
            />
            <div className="uj-szak-container">
                <h1>ESG 1.5 Oktatók</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    <div className="card">
                        <h2>11. Meghívott előadók és óraadók</h2>
                        <p>A szak oktatásában réstvevő meghívott előadók, óraadók száma, kapcsolódó tantárgy kódja, neve; előadó neve, intézménye; téma, külső helyszínen végzett óratartások, intézménylátogatások, üzemlátogatások bemutatása.
A szak oktatásában részt vevő külföldi vendégprofesszorok száma, kapcsolódó tantárgy kódja, neve, téma
</p>
                        <div className="form-group">
                            <textarea
                                name="eloadok_szoveg"
                                value={meghivottEloadokData.eloadok_szoveg}
                                onChange={(e) => setMeghivottEloadokData({ ...meghivottEloadokData, eloadok_szoveg: e.target.value })}
                                placeholder="Adja meg a meghívott előadók és óraadók adatait"
                                disabled={meghivottEloadokData.filling_status === 3}  // Disable if finalized
                            />
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={meghivottEloadokData.filling_status === 3}>Mentés</button>
                        {meghivottEloadokData.filling_status === 3 ? (
                            <button className="szak-button finalize-button" type="button" onClick={handleRevertFinalize}>Véglegesítés visszavonása</button>
                        ) : (
                            <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>Véglegesítés</button>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
};

export default MeghivottEloadok;

import React from "react";
import "../App.css"; 
import "./Home.css"; // Importáljuk a Home.css fájlt

function Home() {
    return (
        <div className="home"> 
            <div className="home-content">
                <div className="intro-text">
                    <p><h1>Tisztelt Szakfelelős Kollégák!</h1></p>
                    <p>
                        Az egyetemi képzésfejlesztési folyamat részeként, a folyamatosan változó munkaerőpiaci, technológiai környezet, valamint a hallgatók újabb generációinak speciális jellemzői miatt (a generációs sajátosságokra helyezve a hangsúlyt) illetve nem utolsósorban minőségbiztosítási folyamatok, az akkreditációs feltételek és elvárások figyelembe vételével a képzésekhez kapcsolódó, többségében már ma is meglévő és jól működő külső munkaerőpiaci, társadalmi kapcsolatrendszer fejlesztését, intézményesítését tűztük ki célul és feladatul.
                    </p>
                    <p>
                        Ennek részeként indítottuk el tavaly augusztusban a szakjelentéseket, amelynek célja, hogy évente egy átfogó képet tudjunk adni a képzéseinkről, s az értékelésbe bevonjuk a külső partnereinket is.
                    </p>
                    <p>
                        A 2022/2023-as tanévben megkezdett képzésfejlesztési munkánkat továbbfejlesztve ebben az évben a szakokra vonatkozó értékeléseket már online formában lehet elkészíteni. A célunk, hogy a jövőben ezek a jelentések és céges kapcsolatlisták évről évre lehetőleg automatikusan létrejöjjenek és az adatoknak csak az értékelése háruljon a szakfelelősre és a külső támogató partnerekre.
                    </p>
                    <p><strong>A szakjelentés célja többszintű:</strong></p>
                    <ul>
                        <li><strong>Belső ellenőrzési folyamat:</strong>
                            <ul>
                                <li>a szakfelelős évente értékeli a szakot, és láthatja a szükséges beavatkozási pontokat;</li>
                                <li>alapot nyújt a mintatanterv újragondolásához, új tárgyak bevezetéséhez, figyelembe véve a folyamatosan változó munkaerőpiaci, technológiai környezetet és a hallgatók generációs sajátosságainak speciális jellemzőit;</li>
                                <li>segítséget ad a szakot bemutató anyagok elkészítéséhez;</li>
                            </ul>
                        </li>
                        <li><strong>MAB akkreditációs feltételeinek és elvárásainak megfelelés:</strong>
                            <ul>
                                <li>a MAB részére bemutatásra kerül, hogy a szakok a PDCA ciklusnak megfelelően az éves munkáikat, tevékenységeiket elvégezve, évente értékelnek, megtalálják a kritikus pontokat, ahol beavatkozás szükséges és megteszik a szükséges javaslatokat a fejlesztésre.</li>
                            </ul>
                        </li>
                        <li><strong>Visszajelzés a szak működéséről:</strong>
                            <ul>
                                <li>Szakfelelős, szakbizottság, dékán részére</li>
                                <li>HÖK, hallgatók részére</li>
                                <li>Külső piaci partnerek felé</li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        <strong>A 2023/2024-es tanévre vonatkozó</strong> értékelő anyag a szakkal kapcsolatosan minden olyan fontos kérdéskört tartalmaz, amelyek a MAB akkreditáció, a minőségellenőrzési munka és a szak folyamatos működése szempontjából szükségesek lehetnek.
                    </p>
                    <p><strong>Köszönjük az együttműködésüket és támogatásukat a képzésfejlesztési munkában!</strong></p>
                </div>
                {/*<img src="/assets/folyamat.svg" alt="Folyamatábra" />*/}
            </div>
        </div>
    );
}

export default Home;

import {jwtDecode} from 'jwt-decode';  // A jwt-decode csomag használata a token dekódolásához
import { useNavigate } from 'react-router-dom';

const checkTokenExpiry = (token) => {
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;  // Másodpercekben
        return decodedToken.exp < currentTime;
    } catch (error) {
        console.error('Invalid token', error);
        return false;
    }
};

export const useTokenExpiryCheck = () => {
    const navigate = useNavigate();

    setInterval(() => {
        const token = localStorage.getItem('token');  // Az access token tárolása
        const isExpired = checkTokenExpiry(token);

        if (isExpired) {
            localStorage.removeItem('token');  // Token eltávolítása
            navigate('/login');  // Átirányítás a login oldalra
        }
    }, 1000);  // másodpercenként ellenőriz
};

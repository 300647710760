import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/NavBar";
import Home from "./routes/Home";
import SzakjelentesLetrehozas from "./routes/SzakjelentesLetrehozas";
import Szakjelentesek from "./routes/Szakjelentesek";
import Szakinfo from "./routes/Szakinfo";
import SzakinfoDetails from "./components/SzakinfoDetails";
import UjSzak from "./components/UjSzak";
import { SzakokContextProvider } from "./context/SzakokContext";
import { UserContextProvider } from "./context/UserContext"; // Helyes context provider
import SzakjelentesekDetailsPage from "./routes/SzakjelentesekDetailsPage";
import KepzesCelja from "./components/szakjelentesCreate/KepzesCelja";
import SzakMintaTerve from "./components/szakjelentesCreate/SzakMintaTerve";
import SzakLetszamAdatok from "./components/szakjelentesCreate/SzakLetszamAdatok";
import VersenytarsElemzes from "./components/szakjelentesCreate/VersenytarsElemzes";
import TehetsegesHallgatok from "./components/szakjelentesCreate/TehetsegesHallgatok";
import TanulmanyiKorulmenyek from "./components/szakjelentesCreate/TanulmanyiKorulmenyek";
import SzakKomm from "./components/szakjelentesCreate/SzakKomm";
import FejlesztesiJavaslatok from "./components/szakjelentesCreate/FejlesztesiJavaslatok";
import Alapadatok from "./routes/Alapadatok";
import AdminOptions from "./routes/AdminOptions";
import AlapadatokView from "./routes/AlapadatokView";
import IdegenNyelviKorulmenyek from "./components/szakjelentesCreate/IdegenNyelviKorulmenyek";
import KotelezoSzakmaiGyakorlat from "./components/szakjelentesCreate/KotelezoSzakmaiGyakorlat";
import MintatantervFejlesztesek from "./components/szakjelentesCreate/MintatantervFejlesztesek";
import Oktatas from "./components/szakjelentesCreate/Oktatas";
import MeghivottEloadok from "./components/szakjelentesCreate/MeghivottEloadok";
import Login from "./routes/Login";
import PrivateRoute from "./components/PrivateRoute"; // PrivateRoute importálása
import SzakInformacio from "./routes/SzakInformacio"; // Make sure this file exists
import { useTokenExpiryCheck } from "./components/TokenExpiryCheck"; // Token expiry check importálása

const App = () => {
  const [sidebar, setSidebar] = useState(false);
  const [responsiveSidebar, setResponsiveSidebar] = useState(false);

  const location = useLocation();
  
  // Token ellenőrzés a token lejáratának folyamatos figyeléséhez
  useTokenExpiryCheck();

  // Elrejti a NavBar-t a bejelentkezési oldalról
  const hideNavbarPaths = ["/login"]; // Itt felsorolhatod azokat az útvonalakat, ahol nem szeretnéd megjeleníteni a NavBar-t
  const showNavBar = !hideNavbarPaths.includes(location.pathname); // Ha az aktuális útvonal nincs a listában, akkor megjelenik a NavBar

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/szakjelentes_letrehozas" ||
      location.pathname === "/szakjelentesek"
    ) {
      setResponsiveSidebar(false);
    } else {
      setResponsiveSidebar(false);
    }
  }, [location.pathname]);

  return (
    <UserContextProvider> {/* Körülvesszük az alkalmazást a UserContextProvider-rel */}
      <SzakokContextProvider>
        {showNavBar && ( // Csak akkor jelenítjük meg a NavBar-t, ha nem a bejelentkezési oldalon vagyunk
          <Navbar sidebar={sidebar} setSidebar={setSidebar} responsive={responsiveSidebar} />
        )}
        <div className={`page-content ${sidebar ? 'active' : ''} ${responsiveSidebar ? 'responsive' : ''}`}>
          <Routes>
            {/* Közvetlen hozzáférés a login oldalhoz, PrivateRoute nélkül */}
            <Route path="/login" element={<Login />} />

            {/* Védett útvonalak PrivateRoute-al */}
            <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/szakinfo" element={<PrivateRoute><Szakinfo /></PrivateRoute>}>
              <Route path=":szakId" element={<SzakinfoDetails />} />
            </Route>
            <Route path="/szakjelentesek" element={<PrivateRoute><Szakjelentesek /></PrivateRoute>} />
            <Route path="/szakjelentesek/:szakId" element={<PrivateRoute><SzakjelentesekDetailsPage /></PrivateRoute>} />
            <Route path="/szakjelentesek/:szakId/alapadatok" element={<PrivateRoute><AlapadatokView /></PrivateRoute>} />
            <Route path="/szakinformacio/:szakkod" element={<PrivateRoute><SzakInformacio /></PrivateRoute>} />

            <Route path="/szakjelentes_letrehozas" element={<PrivateRoute><SzakjelentesLetrehozas /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId" element={<PrivateRoute><UjSzak /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/kepzes-celja" element={<PrivateRoute><KepzesCelja /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/szak-mintaterve" element={<PrivateRoute><SzakMintaTerve /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/szak-letszam-adatok" element={<PrivateRoute><SzakLetszamAdatok /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/versenytars-elemzes" element={<PrivateRoute><VersenytarsElemzes /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/tehetseges-hallgatok" element={<PrivateRoute><TehetsegesHallgatok /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/tanulmanyi-korulmenyek" element={<PrivateRoute><TanulmanyiKorulmenyek /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/szak-kommunikacioja" element={<PrivateRoute><SzakKomm /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/fejlesztesi-javaslatok" element={<PrivateRoute><FejlesztesiJavaslatok /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/idegen-nyelvi-korulmenyek" element={<PrivateRoute><IdegenNyelviKorulmenyek /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/kotelezo-szakmai-gyakorlat" element={<PrivateRoute><KotelezoSzakmaiGyakorlat /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/mintatanterv-fejlesztesek" element={<PrivateRoute><MintatantervFejlesztesek /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/oktatas" element={<PrivateRoute><Oktatas /></PrivateRoute>} />
            <Route path="/szakjelentes_letrehozas/:szakId/meghivott-eloadok" element={<PrivateRoute><MeghivottEloadok /></PrivateRoute>} />
            <Route path="/adminoptions/:szakKod" element={<PrivateRoute><AdminOptions /></PrivateRoute>} />
          </Routes>
        </div>
      </SzakokContextProvider>
    </UserContextProvider>
  );
};

export default App;

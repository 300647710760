import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getIdegenNyelviKorulmenyek, createIdegenNyelviKorulmenyek, updateIdegenNyelviKorulmenyek } from "../../apis/SzakokFinder";
import { SzakokContext } from "../../context/SzakokContext";
import "../../components/Ujszak.css";
import SideTile from "../SideTile";
import { useDropzone } from "react-dropzone";
import Modal from 'react-modal';
import "./IdegenNyelviKorulmenyek.css";


const IdegenNyelviKorulmenyek = () => {
    const { szakId } = useParams();
    const { setSzakok } = useContext(SzakokContext);
    const navigate = useNavigate();

    const [idegenNyelviKorulmenyek, setIdegenNyelviKorulmenyek] = useState({
        hallgato_mobilitas: "",
        idegen_szaknyelv: "",
        filling_status: 1
    });

    const [images, setImages] = useState({
        hallgato_mobilitas_image: [],
        idegen_szaknyelv_image: []
    });

    const [isNew, setIsNew] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null);
    const [finalized, setFinalized] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getIdegenNyelviKorulmenyek(szakId);
                if (response.data.data.idegenNyelviKorulmenyek.length > 0) {
                    setIdegenNyelviKorulmenyek(response.data.data.idegenNyelviKorulmenyek[0]);
                    setFinalized(response.data.data.idegenNyelviKorulmenyek[0].filling_status === 3);
                    await fetchImages();
                    setIsNew(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    // 404 hiba esetén új rekord létrehozása filling_status = 1
                    const newData = { hallgato_mobilitas: "", idegen_szaknyelv: "", filling_status: 1 };
                    await createIdegenNyelviKorulmenyek(szakId, newData);
                    setIdegenNyelviKorulmenyek(newData);
                    window.location.reload(); // Oldal újratöltése új rekord létrehozása után
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        fetchData();
    }, [szakId]);

    const fetchImages = async () => {
        try {
            const mobilitasImages = await fetchImagesBySection("hallgato_mobilitas_image");
            const szaknyelvImages = await fetchImagesBySection("idegen_szaknyelv_image");

            setImages({
                hallgato_mobilitas_image: mobilitasImages,
                idegen_szaknyelv_image: szaknyelvImages
            });
        } catch (error) {
            console.error("Error fetching images:", error);
        }
    };

    const fetchImagesBySection = async (section) => {
        try {
            const token = localStorage.getItem('token');
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            const response = await fetch(`${baseUrl}/szakok/${szakId}/images/${section}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            
            if (!response.ok) {
                throw new Error('Failed to fetch images');
            }
    
            const data = await response.json();
            return data.data || [];
        } catch (error) {
            console.error(`Error fetching images for section ${section}:`, error);
            return [];
        }
    };
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedData = { ...idegenNyelviKorulmenyek, [name]: value };
        setIdegenNyelviKorulmenyek(updatedData);
    };

    const handleDrop = async (acceptedFiles, section) => {
        if (finalized) return; // Disable image upload if finalized
        const formData = new FormData();
        formData.append('image', acceptedFiles[0]);
        formData.append('section', section);
    
        try {
            if (isNew) {
                const response = await createIdegenNyelviKorulmenyek(szakId, idegenNyelviKorulmenyek);
                setIsNew(false);
                setIdegenNyelviKorulmenyek(response.data);
            }
    
            const baseUrl = process.env.REACT_APP_API_BASE_URL; // Környezeti változó használata
            // Token nélküli feltöltési kérés
            const response = await fetch(`${baseUrl}/upload/${szakId}`, {
                method: 'POST',
                body: formData,
            });
    
            if (!response.ok) {
                throw new Error('Failed to upload image');
            }
    
            const uploadResponse = await response.json();
    
            if (uploadResponse && uploadResponse.image_path) {
                const imagePath = uploadResponse.image_path.replace('uploads/', '');
                setImages((prevImages) => ({
                    ...prevImages,
                    [section]: [...prevImages[section], { ...uploadResponse, image_path: imagePath }]
                }));
            } else {
                console.error('Unexpected response format:', uploadResponse);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };
    
    
    const handleDeleteImage = async (imageId, section) => {
        if (finalized) return; // Disable image deletion if finalized
    
        try {
            const baseURL = process.env.REACT_APP_API_BASE_URL;
            await fetch(`${baseURL}/szakjelentes_letrehozas/${szakId}/images/${imageId}`, {
                method: 'DELETE',
            });
    
            setImages((prevImages) => ({
                ...prevImages,
                [section]: prevImages[section].filter(img => img.id !== imageId)
            }));
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };
    

    const handleNext = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/kotelezo-szakmai-gyakorlat`); 
    };

    const handleBack = () => {
        navigate(`/szakjelentes_letrehozas/${szakId}/tehetseges-hallgatok`); 
    };

    const handleSubmit = async (e, status) => {
        e.preventDefault();
        const updatedData = { ...idegenNyelviKorulmenyek, filling_status: status };

        try {
            if (isNew) {
                await createIdegenNyelviKorulmenyek(szakId, updatedData);
            } else {
                await updateIdegenNyelviKorulmenyek(szakId, updatedData);
            }

            window.location.reload(); // Reload the page after save or finalize.
        } catch (error) {
            console.error("Error submitting data:", error);
            alert("Error submitting data.");
        }
    };

    const handleFinalize = (e) => {
        if (idegenNyelviKorulmenyek.hallgato_mobilitas && idegenNyelviKorulmenyek.idegen_szaknyelv) {
            handleSubmit(e, finalized ? 2 : 3);
        } else {
            alert("Minden szöveges mezőt ki kell tölteni!");
        }
    };

    const { getRootProps: getRootPropsMobilitas, getInputProps: getInputPropsMobilitas, isDragActive: isDragActiveMobilitas } = useDropzone({
        onDrop: (files) => handleDrop(files, "hallgato_mobilitas_image"),
        accept: 'image/*',
        disabled: finalized // Disable dropzone if finalized
    });

    const { getRootProps: getRootPropsSzaknyelv, getInputProps: getInputPropsSzaknyelv, isDragActive: isDragActiveSzaknyelv } = useDropzone({
        onDrop: (files) => handleDrop(files, "idegen_szaknyelv_image"),
        accept: 'image/*',
        disabled: finalized // Disable dropzone if finalized
    });

    return (
        <div className="uj-szak-page">
            <SideTile id={szakId} texts={[
                  "Szak alapadatok",
                  "A képzés",
                  "A szak mintatanterve",
                  "Tehetséges, kiemelkedő hallgatói teljesítmények",
                  "Idegen nyelvi követelmények",
                  "Kötelező szakmai gyakorlat",
                  "Mintatanterv fejlesztések, módosítások",
                  "Fejlesztési javaslatok",
                  "Oktatás",
                  "Hallgatókra vonatkozó adatok",
                  "Versenytárs elemzés",
                  "Meghívott előadók és óraadók",
                  "A képzés tanulmányi körülményei",
                  "A szak kommunikációja",
                  "Szakjelentés lezárása"
            ]}
            />
            <div className="uj-szak-container">
                <h1>4 Idegen nyelvi követelmények</h1>
                <form onSubmit={(e) => handleSubmit(e, 2)}>
                    {/* 4.1 Hallgatói mobilitás szöveg és kép */}
                    <div className="card">
                        <h2>4.1 Hallgatói mobilitás</h2>
                        <p>Hallgatói mobilititási programban résztvett hallgatók száma, mobilitás típusa (szakmai, tanulmányi, rövid hallgatói mobilitás, rövid képzési program). Rövid szöveges értékelés. Forrás: <a href="https://seafile.sze.hu/lib/1a083986-6dcf-46af-8ebc-f129698d2010/file/2023_24/Adatok/Kiutazo_Hallgatok_2023_2024.xlsx" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="hallgato_mobilitas"
                                value={idegenNyelviKorulmenyek.hallgato_mobilitas}
                                onChange={handleChange}
                                placeholder="Hallgatói mobilitás"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>
                        <div className="form-group">
                            <div {...getRootPropsMobilitas()} className={`dropzone ${isDragActiveMobilitas ? "active" : ""}`}>
                                <input {...getInputPropsMobilitas()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.hallgato_mobilitas_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                        <img 
                                            src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                            alt="Feltöltött kép" 
                                            onClick={() => setSelectedImage(image.image_path)}
                                        />
                                        <button 
                                            type="button" 
                                            onClick={() => handleDeleteImage(image.id, 'hallgato_mobilitas_image')} 
                                            className="delete_image_button_small"
                                            disabled={finalized} // Disable if finalized
                                        >
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* 4.2 Idegen szaknyelvi képzés szöveg és kép */}
                    <div className="card">
                        <h2>4.2 Idegen szaknyelvi képzés</h2>
                        <p>Mintatantervben lévő, idegen nyelvi képzést biztosító tantárgyak (mintatanterv alapján, általános – nem szaknyelvi – nyelvi felkészítő kurzusok). Forrás: <a href="https://neptun.sze.hu/on-line-kiadott-tajekoztatok" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <textarea
                                name="idegen_szaknyelv"
                                value={idegenNyelviKorulmenyek.idegen_szaknyelv}
                                onChange={handleChange}
                                placeholder="Idegen szaknyelvi képzés"
                                disabled={finalized} // Disable if finalized
                            />
                        </div>
                        <p>Aktuális évben hányan teljesítettek nyelvi kurzust, hány nyelvvizsgát teljesített hallgató van. A tantárgyakba épített szaknyelvi képzést szövegesen szükséges bemutatni. Idegen szaknyelvi képzések a mintatantervben és a tantárgyakba építetten. Forrás: <a href="https://neptun.sze.hu/on-line-kiadott-tajekoztatok" target="_blank" rel="noopener noreferrer">Link</a></p>
                        <div className="form-group">
                            <div {...getRootPropsSzaknyelv()} className={`dropzone ${isDragActiveSzaknyelv ? "active" : ""}`}>
                                <input {...getInputPropsSzaknyelv()} />
                                <p>Húzza ide a képeket vagy kattintson a feltöltéshez</p>
                            </div>
                            <div className="image-upload-container">
                                {images.idegen_szaknyelv_image.map((image, index) => (
                                    <div key={index} className="uploaded-image">
                                    <img 
                                        src={`${process.env.REACT_APP_API_BASE_URL}/uploads/${image.image_path}`} 
                                        alt="Feltöltött kép" 
                                        onClick={() => setSelectedImage(image.image_path)}
                                    />
                                    <button 
                                        type="button" 
                                        onClick={() => handleDeleteImage(image.id, 'idegen_szaknyelv_image')} 
                                        className="delete_image_button_small"
                                        disabled={finalized} // Disable if finalized
                                    >
                                        <i className="material-icons">delete</i>
                                    </button>
                                </div>                                
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="button-container-left">
                        <button className="szak-button" onClick={handleBack}>Előző szakasz</button>
                        <div className="spacer"></div>
                        <button className="szak-button" onClick={handleNext}>Következő szakasz</button>
                    </div>
                    <div className="szak-button-container">
                        <button className="szak-button" type="submit" disabled={finalized}>Mentés</button>
                        <button className="szak-button finalize-button" type="button" onClick={handleFinalize}>
                            {finalized ? "Véglegesítés visszavonása" : "Véglegesítés"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default IdegenNyelviKorulmenyek;
